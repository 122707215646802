
<form [formGroup]="formService.financialSupportForm" >
    <div class="step-header">
        <div class="field-group__container stepheader">
            <div class="section-header">Huidige uitkeringen</div>
            <div class="field-group">
                <app-dualoption-field fieldName="Ontvangt u momenteel een uitkering?"
                                  formControlName="receivesSupport"
                                  [duoOption]="['Ja', 'Nee']"
                                  (inputChange)="setReceivesSupport($event)"
                                  [submitted]="stepService.stepSubmitted[5]"/>
            </div>
        </div>
    </div>
    <div formArrayName="financialSupportForms" *ngIf="receivesSupport">
        <div *ngFor="let financialSupportForm of formService.financialSupportForms.controls; index as i"  [ngClass]="'step '+String(i+1)" [formGroupName]="i">
            <div class="field-group__container">
                <div class="field-group__section__header" >
                    <div class=""> Uitkering</div>
                    <button *ngIf="i>0" class="delete-button" (click)="formService.removeFinancialSupportForm(i)">Verwijderen</button>
                </div>
                <div class="field-group">
                    <app-radio-field [options]="options"
                                     fieldName="Wat voor soort uitkering?"
                                     [hasOpenField]="true"
                                     formControlName="financialSupportType"
                                     [submitted]="stepService.stepSubmitted[5]"/>
                    <app-date-field fieldName="Sinds wanneer ontvangt u deze uitkering?"
                                    formControlName="startDate"
                                    style="width: 50%"
                                    [submitted]="stepService.stepSubmitted[5]"/>
                    <app-select-field fieldName="Uit welk land wordt deze uitkering ontvangen?"
                                      style="width: 50%"
                                      formControlName="country"
                                      [submitted]="stepService.stepSubmitted[5]"/>
                </div>
            </div>
        </div>
        <div class="add-section" (click)="formService.addFinancialSupportForm()">
            <img src="../../../../../assets/icons/add.svg" alt="add"/>
            <div>Uitkering toevoegen</div>
        </div>
    </div>
</form>
