import { Country } from "./types";

export var Countries: Country [] = [
    {code: "NL", name: "Nederland"},
    {code: "BE", name: "België"},
    {code: "DE", name: "Duitsland"},
    {code: "FR", name: "Frankrijk"},
    {code: "ES", name: "Spanje"},
    {code: "AF", name: "Afghanistan"},
    {code: "AL", name: "Albanië"},
    {code: "DZ", name: "Algerije"},
    {code: "AS", name: "Amerikaans Samoa"},
    {code: "AD", name: "Andorra"},
    {code: "AO", name: "Angola"},
    {code: "AI", name: "Anguilla"},
    {code: "AQ", name: "Antarctica"},
    {code: "AG", name: "Antigua en Barbuda"},
    {code: "AR", name: "Argentinië"},
    {code: "AM", name: "Armenië"},
    {code: "AW", name: "Aruba"},
    {code: "AU", name: "Australië"},
    {code: "AT", name: "Oostenrijk"},
    {code: "AZ", name: "Azerbeidzjan"},
    {code: "BS", name: "Bahama's"},
    {code: "BH", name: "Bahrein"},
    {code: "BD", name: "Bangladesh"},
    {code: "BB", name: "Barbados"},
    {code: "BY", name: "Wit-Rusland"},
    {code: "BZ", name: "Belize"},
    {code: "BJ", name: "Benin"},
    {code: "BM", name: "Bermuda"},
    {code: "BT", name: "Bhutan"},
    {code: "BO", name: "Bolivia"},
    {code: "BQ", name: "Bonaire"},
    {code: "BA", name: "Bosnië en Herzegovina"},
    {code: "BW", name: "Botswana"},
    {code: "BV", name: "Bouveteiland"},
    {code: "BR", name: "Brazilië"},
    {code: "IO", name: "British Indian Ocean Territory"},
    {code: "BN", name: "Brunei Darussalam"},
    {code: "BG", name: "Bulgarije"},
    {code: "BF", name: "Burkina Faso"},
    {code: "BI", name: "Burundi"},
    {code: "CV", name: "Cabo Verde"},
    {code: "KH", name: "Cambodja"},
    {code: "CM", name: "Kameroen"},
    {code: "CA", name: "Canada"},
    {code: "KY", name: "Kaaimaneilanden"},
    {code: "CF", name: "Centraal-Afrikaanse Republiek"},
    {code: "TD", name: "Tsjaad"},
    {code: "CL", name: "Chili"},
    {code: "CN", name: "China"},
    {code: "CX", name: "Christmas Island"},
    {code: "CC", name: "Cocos Eilanden"},
    {code: "CO", name: "Colombia"},
    {code: "KM", name: "Comoren"},
    {code: "CD", name: "Democratische Republiek van Congo"},
    {code: "CG", name: "Congo"},
    {code: "CK", name: "Cookeilanden"},
    {code: "CR", name: "Costa Rica"},
    {code: "HR", name: "Kroatië"},
    {code: "CU", name: "Cuba"},
    {code: "CW", name: "Curaçao"},
    {code: "CY", name: "Cyprus"},
    {code: "CZ", name: "Tsjechië"},
    {code: "CI", name: "Ivoorkust"},
    {code: "DK", name: "Denemarken"},
    {code: "DJ", name: "Djibouti"},
    {code: "DM", name: "Dominica"},
    {code: "DO", name: "Dominicaanse Republiek"},
    {code: "EC", name: "Ecuador"},
    {code: "EG", name: "Egypte"},
    {code: "SV", name: "El Salvador"},
    {code: "GQ", name: "Equatoriaal-Guinea"},
    {code: "ER", name: "Eritrea"},
    {code: "EE", name: "Estland"},
    {code: "SZ", name: "Eswatini"},
    {code: "ET", name: "Ethiopië"},
    {code: "FK", name: "Falklandeilanden"},
    {code: "FO", name: "Faeröer Eilanden"},
    {code: "FJ", name: "Fiji"},
    {code: "FI", name: "Finland"},
    {code: "GF", name: "Frans-Guyana"},
    {code: "PF", name: "Frans-Polynesië"},
    {code: "TF", name: "Franse Zuidelijke Gebieden"},
    {code: "GA", name: "Gabon"},
    {code: "GM", name: "Gambia"},
    {code: "GE", name: "Georgië"},
    {code: "GH", name: "Ghana"},
    {code: "GI", name: "Gibraltar"},
    {code: "GR", name: "Griekenland"},
    {code: "GL", name: "Groenland"},
    {code: "GD", name: "Grenada"},
    {code: "GP", name: "Guadeloupe"},
    {code: "GU", name: "Guam"},
    {code: "GT", name: "Guatemala"},
    {code: "GG", name: "Guernsey"},
    {code: "GN", name: "Guinea"},
    {code: "GW", name: "Guinee-Bissau"},
    {code: "GY", name: "Guyana"},
    {code: "HT", name: "Haïti"},
    {code: "HM", name: "Heard Island en McDonald Islands"},
    {code: "VA", name: "Heilige Stoel"},
    {code: "HN", name: "Honduras"},
    {code: "HK", name: "Hong Kong"},
    {code: "HU", name: "Hongarije"},
    {code: "IS", name: "IJsland"},
    {code: "IN", name: "India"},
    {code: "ID", name: "Indonesië"},
    {code: "IR", name: "Iran"},
    {code: "IQ", name: "Irak"},
    {code: "IE", name: "Ierland"},
    {code: "IM", name: "Isle of Man"},
    {code: "IT", name: "Italië"},
    {code: "JM", name: "Jamaica"},
    {code: "JP", name: "Japan"},
    {code: "JE", name: "Jersey"},
    {code: "JO", name: "Jordan"},
    {code: "KZ", name: "Kazachstan"},
    {code: "KE", name: "Kenia"},
    {code: "KI", name: "Kiribati"},
    {code: "KP", name: "Zuid Korea"},
    {code: "KR", name: "NoordKorea"},
    {code: "KW", name: "Koeweit"},
    {code: "KG", name: "Kirgizië"},
    {code: "LA", name: "Democratische Volksrepubliek Laos"},
    {code: "LV", name: "Letland"},
    {code: "LB", name: "Libanon"},
    {code: "LS", name: "Lesotho"},
    {code: "LR", name: "Liberia"},
    {code: "LY", name: "Libië"},
    {code: "LI", name: "Liechtenstein"},
    {code: "LT", name: "Litouwen"},
    {code: "LU", name: "Luxemburg"},
    {code: "MO", name: "Macao"},
    {code: "MG", name: "Madagaskar"},
    {code: "MW", name: "Malawi"},
    {code: "MY", name: "Maleisië"},
    {code: "MV", name: "Malediven"},
    {code: "ML", name: "Mali"},
    {code: "MT", name: "Malta"},
    {code: "MH", name: "Marshalleilanden"},
    {code: "MQ", name: "Martinique"},
    {code: "MR", name: "Mauritanië"},
    {code: "MU", name: "Mauritius"},
    {code: "YT", name: "Mayotte"},
    {code: "MX", name: "Mexico"},
    {code: "FM", name: "Micronesië"},
    {code: "MD", name: "Moldavië"},
    {code: "MC", name: "Monaco"},
    {code: "MN", name: "Mongolië"},
    {code: "ME", name: "Montenegro"},
    {code: "MS", name: "Montserrat"},
    {code: "MA", name: "Marokko"},
    {code: "MZ", name: "Mozambique"},
    {code: "MM", name: "Myanmar"},
    {code: "NA", name: "Namibië"},
    {code: "NR", name: "Nauru"},
    {code: "NP", name: "Nepal"},
    {code: "NC", name: "Nieuw-Caledonië"},
    {code: "NZ", name: "Nieuw-Zeeland"},
    {code: "NI", name: "Nicaragua"},
    {code: "NE", name: "Niger"},
    {code: "NG", name: "Nigeria"},
    {code: "NU", name: "Niue"},
    {code: "NF", name: "Norfolkeiland"},
    {code: "MP", name: "Noordelijke Marianen"},
    {code: "NO", name: "Noorwegen"},
    {code: "OM", name: "Oman"},
    {code: "PK", name: "Pakistan"},
    {code: "PW", name: "Palau"},
    {code: "PS", name: "Palestina, Staat"},
    {code: "PA", name: "Panama"},
    {code: "PG", name: "Papoea-Nieuw-Guinea"},
    {code: "PY", name: "Paraguay"},
    {code: "PE", name: "Peru"},
    {code: "PH", name: "Filippijnen"},
    {code: "PN", name: "Pitcairn"},
    {code: "PL", name: "Polen"},
    {code: "PT", name: "Portugal"},
    {code: "PR", name: "Puerto Rico"},
    {code: "QA", name: "Qatar"},
    {code: "MK", name: "Republiek Noord-Macedonië"},
    {code: "RO", name: "Roemenië"},
    {code: "RU", name: "Russische Federatie"},
    {code: "RW", name: "Rwanda"},
    {code: "RE", name: "Réunion"},
    {code: "BL", name: "Saint Barthélemy"},
    {code: "SH", name: "Sint-Helena, Ascension en Tristan da Cunha"},
    {code: "KN", name: "Saint Kitts en Nevis"},
    {code: "LC", name: "Saint Lucia"},
    {code: "MF", name: "Sint Maarten (Frans deel)"},
    {code: "PM", name: "Saint Pierre en Miquelon"},
    {code: "VC", name: "Saint Vincent en de Grenadines"},
    {code: "WS", name: "Samoa"},
    {code: "SM", name: "San Marino"},
    {code: "ST", name: "Sao Tomé en Principe"},
    {code: "SA", name: "Saoedi-Arabië"},
    {code: "SN", name: "Senegal"},
    {code: "RS", name: "Servië"},
    {code: "SC", name: "Seychellen"},
    {code: "SL", name: "Sierra Leone"},
    {code: "SG", name: "Singapore"},
    {code: "SX", name: "Sint Maarten (Nederlands deel)"},
    {code: "SK", name: "Slowakije"},
    {code: "SI", name: "Slovenië"},
    {code: "SB", name: "Salomonseilanden"},
    {code: "SO", name: "Somalië"},
    {code: "ZA", name: "Zuid-Afrika"},
    {code: "GS", name: "Zuid-Georgië en de Zuidelijke Sandwicheilanden"},
    {code: "SS", name: "Zuid-Soedan"},
    {code: "LK", name: "Sri Lanka"},
    {code: "SD", name: "Soedan"},
    {code: "SR", name: "Suriname"},
    {code: "SJ", name: "Svalbard en Jan Mayen"},
    {code: "SE", name: "Zweden"},
    {code: "CH", name: "Zwitserland"},
    {code: "SY", name: "Syrische Arabische Republiek"},
    {code: "TW", name: "Taiwan"},
    {code: "TJ", name: "Tadzjikistan"},
    {code: "TZ", name: "Tanzania, Verenigde Republiek"},
    {code: "TH", name: "Thailand"},
    {code: "TL", name: "Timor-Leste"},
    {code: "TG", name: "Togo"},
    {code: "TK", name: "Tokelau"},
    {code: "TO", name: "Tonga"},
    {code: "TT", name: "Trinidad en Tobago"},
    {code: "TN", name: "Tunesië"},
    {code: "TR", name: "Turkije"},
    {code: "TM", name: "Turkmenistan"},
    {code: "TC", name: "Turks- en Caicoseilanden"},
    {code: "TV", name: "Tuvalu"},
    {code: "UG", name: "Oeganda"},
    {code: "UA", name: "Oekraïne"},
    {code: "AE", name: "Verenigde Arabische Emiraten"},
    {code: "GB", name: "Verenigd Koninkrijk van Groot-Brittannië en Noord-Ierland"},
    {code: "UM", name: "kleine afgelegen eilanden van de Verenigde Staten"},
    {code: "VS", name: "Verenigde Staten van Amerika"},
    {code: "UY", name: "Uruguay"},
    {code: "UZ", name: "Oezbekistan"},
    {code: "VU", name: "Vanuatu"},
    {code: "VE", name: "Venezuela"},
    {code: "VN", name: "Vietnam"},
    {code: "VG", name: "Virgin Islands (Brits)"},
    {code: "VI", name: "Virgin Islands (VS)"},
    {code: "WF", name: "Wallis en Futuna"},
    {code: "EH", name: "Westelijke Sahara"},
    {code: "YE", name: "Jemen"},
    {code: "ZM", name: "Zambia"},
    {code: "ZW", name: "Zimbabwe"},
    {code: "AX", name: "Ålandeilanden"}
]
