<div class="footer-component-container">
    <div class="home-section__content footer-component">
        <div class="footer-section__about-us">
            <div class="title-small">Over ons</div>
            <p class="footer-text">Via Mijn Bijslag is het mogelijk gemakkelijk kindertoeslag aan te vragen. Door onze expertise op het gebied van de regeling en het werken met het SVB kunnen wij uw aanvraag snel en correct indienen waardoor u gegarandeerd ben van de meest snelle en correcte verwerking.</p>
        </div>
        <div class="footer-links__container">
            <div class="footer-handylinks__container">
                <div class="title-small">Handige links</div>
                <ul class="footer-handylink-list">
                    <li><a class="footer-handylink" href=""> Home</a></li>
                    <li><a class="footer-handylink" href="stap"> Aanvraag starten</a></li>
                    <li><a class="footer-handylink" href="algemene-voorwaarden"> Voorwaarden</a></li>
                    <li><a class="footer-handylink" href="disclaimer"> Disclaimer</a></li>
                    <li><a class="footer-handylink" href="privacy"> Privacybeleid</a></li>
                </ul>
            </div>
            <div class="footer-contactus">
                <div class="title-small">Contacteer ons</div>
                <address >
                    <img class="footer-contactus__email-icon" src="../../../assets/icons/mail.svg" alt="">
                    <a class="footer-contactus__email" href="mailto:info@mijn-bijslag.nl">info&#64;mijn-bijslag.nl</a>
                </address>
            </div>
        </div>

    </div>
    <div class="copyright-text">© 2024 Mijn Bijslag. All rights reserved.</div>
    <div [class]="environment.production? 'build production': 'build development'"></div>
</div>
