import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FAQComponent } from './pages/faq/faq.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { StepComponent } from './pages/Step/step.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { authGuard } from './auth.guard';
import { StepPaymentComponent } from './pages/Step/Steps/step-payment/step-payment.component';
import {PaymentStatusComponent} from "./pages/payment-status/payment-status.component";
import {DisclaimerComponent} from "./pages/disclaimer/disclaimer.component";

export const routes: Routes = [
    {
        path:"",
        component: LayoutComponent,
        children:[
            {
                path:'',
                component: HomeComponent
            },
            {
                path:"faq",
                title: "Mijn Bijslag - Meestgestelde vragen",
                component: FAQComponent,
            },
            {
                path:"meer-weten",
                title:"Mijn Bijslag -  Meer weten",
                component:AboutUsComponent,
            },
            {
                path:"algemene-voorwaarden",
                title: "Mijn Bijslag - Algemene voorwaarden",
                component:TermsComponent,
            },
            {
                path:"contact",
                title:"Mijn Bijslag - Contact",
                component:ContactComponent,
            },
            {
                path:"privacy",
                title:"Mijn Bijslag - Privacy",
                component: PrivacyComponent
            },
            {
                path:"stap",
                title:"Mijn Bijslag - Aanvraag",
                component: StepComponent
            },
            {
                path:"disclaimer",
                title: "Mijn Bijslag - Disclaimer",
                component: DisclaimerComponent
            },
            {
                path:"bedankt-voor-uw-aanvraag",
                title: "Mijn Bijslag - betaling",
                component:PaymentStatusComponent
            },
        ]
    },
    {
        path:"login",
        title: "Mijn Bijslag - Login",
        component: LoginComponent
    },
    {
        path:"admin",
        component: AdminComponent,
        title: "Mijn Bijslag - Admin",
        canActivate: [authGuard]
    },


];
