import { Component } from '@angular/core';
import { RequestbuttonComponent } from '../requestbutton/requestbutton.component';

@Component({
  selector: 'app-request-section',
  standalone: true,
  imports: [RequestbuttonComponent],
  templateUrl: './request-section.component.html',
  styleUrls: ['./request-section.component.css', './request-section.mobile.component.css', "../../pages/home/home.desktop.component.css"]
})
export class RequestSectionComponent {

  public readonly imagesPath = "../../../assets/images/";

}
