import { CommonModule, getLocaleMonthNames } from '@angular/common';
import {
    Component,
    Injector,
    Input,
    forwardRef,
    getDebugNode,
    OnInit,
    AfterViewChecked,
    AfterViewInit, AfterContentInit, AfterContentChecked
} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import { ErrorComponent } from '../../error/error.component';
import { FormModelService } from '../../../services/formModel/form-model.service';
import {GetInputLabelClass} from "../Helpers";

@Component({
  selector: 'app-gender-field',
  standalone: true,
  imports: [
    ErrorComponent,
    CommonModule
  ],
  templateUrl: './gender-field.component.html',
  styleUrl: '../../step-field/step-field.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderFieldComponent),
      multi: true
    }
  ]
})
export class GenderFieldComponent implements ControlValueAccessor, OnInit, AfterContentInit, AfterContentChecked{
    @Input() fieldName:string="Geslacht";
    @Input() question:string="";
    @Input() errors: string[] = [];
    @Input() submitted: boolean = false;

    public formControl: NgControl|undefined;

    public manClicked: boolean = false;
    public womanClicked: boolean = false;
    public manClass: string = "gender-field__content"
    public womanClass: string = "gender-field__content";
    public value: string="";
    public isRequired: boolean= false;

    constructor(private _injector: Injector, private formService: FormModelService){}

    ngAfterContentChecked(): void {
        this.handleGenderClick(this.formControl?.value);
    }

    ngOnInit(): void {
        this.formControl = this._injector.get(NgControl);
    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }
    private handleGenderClick(gender:string): void{
        if(gender=="man"){
            this.manClass+=" active";
            this.womanClass = this.womanClass.replace(" active", "")
            this.manClicked = !this.manClicked
            if(this.womanClicked){
                this.womanClicked = !this.womanClicked
            }
        }
        else if(gender=="vrouw"){
            this.womanClass+=" active";
            this.manClass = this.manClass.replace(" active", "")
            this.womanClicked = !this.womanClicked
            if(this.manClicked){
                this.manClicked = !this.manClicked
            }
        }
    }

    onChange: any = () => {};
    onTouched: any = () => {};

    writeValue(gender: string): void {
        this.value = gender
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onValueChange(gender: string): void {
        this.handleGenderClick(gender);
        this.value=gender;
        this.onChange(this.value);
        this.onTouched();
    }

    showError():boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
          this.errors = this.formService.getErrorMsg(this.formControl)
          return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;
}
