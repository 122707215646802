import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RequestbuttonComponent} from "../../SharedComponents/requestbutton/requestbutton.component";
import {CommonModule} from "@angular/common";
import {DataService} from "../../services/data/data.service";

@Component({
  selector: 'app-payment-status',
  standalone: true,
    imports: [
        RequestbuttonComponent,
        CommonModule
    ],
  templateUrl: './payment-status.component.html',
  styleUrl: './payment-status.component.css'
})
export class PaymentStatusComponent implements OnInit{

    isPaymentSuccess: boolean|undefined;
    paymentIntentClientSecret: string = "";
    paymentIntent: string = "";


    constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) {
    }
    ngOnInit() {
        this.route.queryParamMap.subscribe( params =>{
            this.isPaymentSuccess = params.get('redirect_status')=="succeeded"
            this.paymentIntentClientSecret = params.get('payment_intent_client_secret') ?? "";
            this.paymentIntent = params.get('payment_intent') ?? "";

            this.dataService.updateState(this.paymentIntentClientSecret)
                .subscribe(response => {
                    console.log("State updated", response)
                }, error => {console.error("Error updating state", error)})

            setTimeout(()=>{this.router.navigate(["/"])}, 15000);
        })
    }
}
