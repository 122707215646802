<div class="text-field">
    <div *ngIf="fieldName!=''" class="input-label__container">
        <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf="isRequired" class="input-label__required-star"> *</span></div>
    </div>
    <mat-form-field subscriptSizing="dynamic" class="mat-form-field" appearance="outline"
                    style="flex: 1; width: 100%">
        <input matInput [placeholder]="placeholder" (change)=onValueChange($event) [value]="value" style="font-size: .875rem">
    </mat-form-field>
    <app-error *ngIf="showError()" [errors]="errors"/>
</div>
