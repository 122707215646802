<div class="select-field">
    <div *ngIf="fieldName!=''" class="input-label__container">
        <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf=isRequired class="input-label__required-star"> *</span></div>
    </div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-select (selectionChange)="setSelectedValue($event)" [value]="value" placeholder="Land" style="font-size: .875rem;">
            <mat-option style="background-color: #F6F6F6;" *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <app-error *ngIf="showError()" [errors]="errors"/>
</div>
