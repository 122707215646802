import { CommonModule, DatePipe } from '@angular/common';
import {Component, Injector, Input, forwardRef, AfterContentChecked, OnInit, AfterContentInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, MatOption} from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ErrorComponent } from '../../error/error.component';
import { FormModelService } from '../../../services/formModel/form-model.service';
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import {GetInputLabelClass} from "../Helpers";
import {Countries} from "../../countries";
import {MatSelect, MatSelectChange} from "@angular/material/select";
@Component({
  selector: 'app-date-field',
  standalone: true,
    imports: [
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ErrorComponent,
        CommonModule,
        MatOption,
        MatSelect
    ],
  templateUrl: './date-field.component.html',
  styleUrls: ['../../step-field/step-field.component.css', './date-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldComponent),
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    DatePipe
  ],
})
export class DateFieldComponent implements ControlValueAccessor, OnInit,AfterContentChecked, AfterContentInit {
    @Input() fieldName: string  ="";
    @Input() placeholder: string  ="dd/mm/jjjj";
    @Input() errors: string[] = [];
    @Input() submitted: boolean = false;
    @Input() maxDate: Date = new Date;

    MonthStrings: string[] = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    private formControl: NgControl|undefined;
    public value: string = ""
    public isRequired: boolean = false;

    onChange: any = () => {};
    onTouched: any = () => {};

    constructor(private dateAdapter: DateAdapter<Date>, private datePipe: DatePipe, private _injector: Injector, private formService: FormModelService){
        this.dateAdapter.setLocale('nl'); // To set date format to dd/mm/jjjj
    }

    ngOnInit(): void {
        this.formControl = this._injector.get(NgControl)
    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }

    ngAfterContentChecked(): void {
        this.handleNewValue(this.formControl?.value);
    }
    writeValue(value: Date): void {}

    normalizeDate(date: Date|string|null):string{
        return date!=null? this.datePipe.transform(date, "dd-MM-YYYY")?? "_/_/_": "_/_/_";
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setValue(event: MatDatepickerInputEvent<Date>): void{
        this.handleNewValue(this.normalizeDate(event.value))
    }

    handleNewValue(val:string){
        this.value = val;
        this.onChange(this.value);
        this.onTouched();
    }

    toDate(date:string): Date{
        if(date=="")
            return new Date("");
        let year:number=0;
        let month:number=0;
        let day:number=0;
        if(date.includes("/")){
            [day, month, year] = date.split("/").map(Number);
        }else if(date.includes("-")){
            [day, month, year] = date.split("-").map(Number);
        }
        return new Date(year, month-1,day);
    }

    showError():boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
            this.errors = this.formService.getErrorMsg(this.formControl)
            return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;
    protected readonly Years = Array.from({length:100}, (_, i)=>new Date().getFullYear()-i).map(i=>i.toString());
    protected readonly Months = Array.from({length:12}, (_, i)=>i+1).map(i=>i.toString());
    protected Days: string[] = Array.from({length:30}, (_, i)=>i+1).map(i=>i.toString());

    private getDaysInMonth(month: string, year: string): number {
        if(month==""||year==""){
            return 0;
        }
        return new Date(Number(year), Number(month), 0).getDate();
    }
    public setSelectedYear(event: MatSelectChange){
        this.setYear(event.value);
        this.setMonth("");
        this.setDay("");
    }

    public setSelectedMonth(event: MatSelectChange){
        this.setMonth(event.value);
        this.setDay("");
    }

    public setSelectedDay(event: MatSelectChange){
        this.setDay(event.value);
    }

    public setYear(year:string){
        if(this.value==""){
            this.value = "--";
        }
        let dateAsList = this.value.split("-");
        dateAsList[2] = year;
        this.value = dateAsList.join("-");
        this.handleNewValue(this.value);
    }

    public setMonth(month:string){
        if(this.value==""){
            this.value = "--";
        }
        let dateAsList = this.value.split("-");
        dateAsList[1] = month;
        this.value = dateAsList.join("-");
        this.handleNewValue(this.value);
    }

    public setDay(day:string){
        if(this.value==""){
            this.value = "--";
        }
        let dateAsList = this.value.split("-");
        dateAsList[0] = day;
        this.value = dateAsList.join("-");
        this.handleNewValue(this.value);
    }

    public get SelectedYear():string{
        if(this.value==""){
            this.value = "--";
        }

        return this.value.split("-")[2];
    }

    public get SelectedMonth():string{
        if(this.value==""){
            this.value = "--";
        }
        return this.value.split("-")[1];
    }

    public get SelectedDay():string{
        if(this.value==""){
            this.value = "";
        }
        return this.value.split("-")[0];
    }

    public setDays(event: boolean){
        if(this.SelectedYear!="" && this.SelectedMonth!=null){
            this.Days = Array.from({length:this.getDaysInMonth(this.SelectedYear, String(30))}, (_, i)=>i+1).map(i=>i.toString());
        }
    }

    protected readonly Number = Number;
}
