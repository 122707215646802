import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-requestbutton',
  standalone: true,
  imports: [],
  templateUrl: './requestbutton.component.html',
  styleUrl: './requestbutton.component.css'
})
export class RequestbuttonComponent implements OnInit{
  @Input() buttonText: string = "Start Uw Aanvraag";
  @Input() url: string = "";
  @Input() light: boolean = false;
  @Input() small: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() buttonClick = new EventEmitter<void>();

  constructor(public route: Router){

  }

  onClick(){
    if(this.url!=""){
        this.route.navigate(["/"+this.url]);
    }else{
        this.route.navigateByUrl(this.route.url);
    }
      this.buttonClick.emit();


  }


  public className: string = this.light? 'request-button light':'request-button'

    getButtonClass(): string{
      let className = "request-button";
      if(this.light){
          className+=" light";
      }

      if(this.isLoading){
        className+=" request-button__loading";
      }

      return className;
    }

  ngOnInit(): void {
    if (this.light)
      this.className = this.className+" light";
    if (this.small)
      this.className = this.className+" small";

  }
}
