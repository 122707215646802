<div class="app-header">
    <div class="app-header-container">
        <div class="logo-container">
            <img class="logo" src="../../../assets/icons/Logo.svg" (click)="onLogoClick()" alt="">
        </div>
        <div class="header-menu">
            <div *ngIf="isMobile; else desktopMenu"  class="header-burger-menu" (click)="menuService.toggleMenu()">
                <div class="material-icons">{{ menuService.isMenuDown ? "close" : "menu" }}</div>
            </div>
        </div>
    </div>
</div>

<ng-template #desktopMenu>
    <ul class="header-list-menu">
        <li *ngFor="let page of pages" class="header-menu-button">
            <a [ngClass]="isActive(page.url)? 'nav-button-active' :'nav-button'" [href]="page.url">{{page.title}}</a>
        </li>
        <app-requestbutton [small]="true" url="stap"></app-requestbutton>
    </ul>
</ng-template>
