import { Injectable } from '@angular/core';
import {
    loadStripe,
    Stripe, StripeElements,
    StripeIdealBankElement,
    StripePaymentElement,
    Appearance
} from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import {PaymentReceipt} from "../../models/payment.model";
import {Router} from "@angular/router";
import {getShorthandId} from "../../SharedComponents/fields/Helpers";

@Injectable({
  providedIn: 'root'
})
export class StripeService {

    public stripe: Stripe | null = null;
    /*public card: StripeCardElement | undefined;*/
    public payment: StripePaymentElement | undefined;
    private elements: StripeElements | undefined;
    public applicationId: string = "";

    public receipt: PaymentReceipt = new PaymentReceipt();
    private redirectUrl: string = "";

    constructor(private route: Router) {}
    public async initializeStripe(){
        this.stripe = await loadStripe(environment.stripePublishableKey);

        const appearance: Appearance = {
            theme: "stripe",
            variables: {
                colorPrimary: '#6a752c',
                borderRadius: '0',
            }
        }
        this.elements = this.stripe?.elements({
            clientSecret: this.receipt.clientSecret,
            loader: 'auto',
            locale: 'nl',
            appearance: appearance
        });
        if(this.elements==null){
            console.error("Stripe elements not loaded");
            return;
        }
        this.payment = this.elements.create('payment');
        this.payment?.mount("#ideal-element");
        console.log("stripe is loaded.")
    }

    async handlePayment(name: string, email: string, applicationId: string): Promise<boolean>{
        console.log("name:", name);
        console.log("email:", email);
        if(!this.stripe){
            console.error("Stripe.js is not loaded");
            return false;
        }
        else if(this.receipt.clientSecret==""){
            console.error("secret not set");
            return false
        }
        else if( name=="" || email=="" ){
            console.error("Name or email not provided");
            if(!environment.production){
                name = "testName";
                email = "test@gmail.com";
            }else
            {
                return false;
            }

        }
        /*if(this.paymentMethod==""){
            console.error("Payment method not set");
            return false;
        }
        if(this.paymentMethod=="card")
            return this.handleCardPayment(name, email, this.redirectUrl);
        else if(this.paymentMethod=="ideal")
            return this.handleIDealPayment(name, email, this.redirectUrl);
*/
        this.redirectUrl = environment.hostUrl+"bedankt-voor-uw-aanvraag?orderid="+getShorthandId(applicationId);
        return this.handleIDealPayment(name, email, this.redirectUrl);
    }

    /*async handleCardPayment(name: string, email: string, redirectUrl: string): Promise<boolean>{
        if(this.paymentMethod!='card'){
            return false;
        }
        if(!this.card){
            console.error("Card info not retrieved");
            return false
        }
        const result = await this.stripe?.confirmCardPayment(this.receipt.clientSecret, {
            payment_method: {
                card: this.card,
                billing_details:{ name: name, email: email }
            },
            return_url: redirectUrl
        });

        return result?.paymentIntent?.status == "succeeded";
    }*/

    async handleIDealPayment(name: string, email: string, redirectUrl: string): Promise<boolean>{
        /*if(this.paymentMethod!='ideal'){
            return false;
        }
        if(!this.ideal){
            console.error("Ideal info not retrieved");
            return false
        }*/
        this.elements?.submit();
        console.log("Secret:", this.receipt.clientSecret);
        const result = await this.stripe?.confirmPayment({
            elements: this.elements,
            clientSecret: this.receipt.clientSecret,
            redirect: 'if_required',
            confirmParams: {
                return_url: redirectUrl,
            },
        });
        /*const result = await this.stripe?.confirmPayment(this.receipt.clientSecret, {
            payment_method: {
                ideal: this.ideal,
                billing_details:{
                    name: name,
                    email: email
                }
            },
            return_url: redirectUrl
        });
*/
        console.log("Result:", result);
        return result?.paymentIntent?.status == "succeeded";
    }

}
