import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Injector,
    Input,
    Output,
    forwardRef,
    AfterContentChecked,
    OnInit,
    AfterContentInit
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ErrorComponent } from '../../error/error.component';
import { FormModelService } from '../../../services/formModel/form-model.service';
import {generateUniqueId, GetInputLabelClass} from "../Helpers";

@Component({
  selector: 'app-dualoption-field',
  standalone: true,
  imports: [
    CommonModule,
    ErrorComponent,
    MatRadioModule
  ],
  templateUrl: './dualoption-field.component.html',
  styleUrl: '../../step-field/step-field.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DualoptionFieldComponent),
      multi: true
    },
  ]
})
export class DualoptionFieldComponent implements ControlValueAccessor, OnInit, AfterContentChecked, AfterContentInit{
    @Input() fieldName:string="";
    @Input() duoOption: [string, string] = ["",""]
    @Output() inputChange = new EventEmitter<string>;
    @Input() errors: string[] = []
    @Input() submitted: boolean = false;

    public isRequired: boolean = false;
    public value: string = "";
    private formControl: NgControl|undefined;
    public uniqueId: string = ""

    constructor(private _injector: Injector, private formService: FormModelService){}

    onChange: any = () => {};
    onTouched: any = () => {};

    ngOnInit(): void {
        this.uniqueId = generateUniqueId();
        this.formControl = this._injector.get(NgControl)

    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }
    ngAfterContentChecked(): void {
        this.handleNewValue(this.formControl?.value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    handleNewValue(value: string): void{
        this.value = value;
        this.onChange(value);
        this.onTouched();
        this.inputChange.emit(value)
    }

    showError():boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
            this.errors = this.formService.getErrorMsg(this.formControl)
            return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;

}
