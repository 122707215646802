import { CommonModule } from '@angular/common';
import {Component, Injector, Input, forwardRef, OnInit, AfterContentChecked, AfterContentInit} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import { Country } from '../../types';
import { Countries } from '../../countries';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import { ErrorComponent } from '../../error/error.component';
import { FormModelService } from '../../../services/formModel/form-model.service';
import {GetInputLabelClass} from "../Helpers";

@Component({
  selector: 'app-select-field',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ErrorComponent,
    MatSelectModule
  ],
  templateUrl: './select-field.component.html',
  styleUrls: ['../../step-field/step-field.component.css', './select-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true
    }
  ]
})
export class SelectFieldComponent implements ControlValueAccessor, OnInit, AfterContentChecked, AfterContentInit{

    @Input() question: string = "";
    @Input() fieldName: string  ="";
    @Input() errors: string[] = []
    @Input() submitted: boolean = false;

    private formControl: NgControl|undefined;

    public countries: Country[] = Countries;
    public value: string = "";
    public isRequired: boolean= false;

    onChange: any = () => {};
    onTouched: any = () => {};

    constructor(private _injector: Injector, private formService: FormModelService){}

    ngOnInit(): void {
        this.formControl = this._injector.get(NgControl);
    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }

    ngAfterContentChecked(): void {
        this.handleNewValue(this.formControl?.value);
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setSelectedValue(event: MatSelectChange): void{
        this.handleNewValue(event.value);
    }

    handleNewValue(val: string){
        this.value = val;
        this.onChange(val);
        this.onTouched();
    }

    showError(): boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
            this.errors = this.formService.getErrorMsg(this.formControl)
            return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;
}
