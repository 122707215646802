import { Component } from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-notification-dialogue',
  standalone: true,
    imports: [
        MatDialogModule,
        MatButton,
    ],
  templateUrl: './notification-dialogue.component.html',
  styleUrl: './notification-dialogue.component.css'
})
export class NotificationDialogueComponent {

}
