<div class="step-overview">
    <div class="overview-section">
        <div class="section-header">
            <div class="title">Gegevens aanvrager</div>
        </div>
        <div class="section-column">
            <div class="section">
                <div class="info-field">
                    <div class="label">Naam:</div>
                    <div class="field">{{applicantControlValue('name')}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Geboortedatum:</div>
                    <div class="field">{{applicantControlValue('birthdate')}}</div>
                </div>
                <div class="info-field last">
                    <div class="label">E-mail:</div>
                    <div class="field">{{applicantControlValue('email')}}</div>
                </div>
            </div>
            <div class="section">
                <div class="info-field">
                    <div class="label">Achternaam:</div>
                    <div class="field">{{applicantControlValue("surname")}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Telefoonnummer:</div>
                    <div class="field">{{applicantControlValue("phonenumber")}}</div>
                </div>
                <div class="info-field last">
                    <div class="label">Burgerservicenummer:</div>
                    <div class="field">{{applicantControlValue("bsn")}}</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="formService.applicantHasPartner" class="overview-section">
        <div class="title">Gegevens partner</div>
        <div class="section-column">
            <div class="section">
                <div class="info-field">
                    <div class="label">Naam:</div>
                    <div class="field">{{partnerControlValue("name")}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Geboortedatum:</div>
                    <div class="field">{{partnerControlValue("birthdate")}}</div>
                </div>
                <div class="info-field last">
                    <div class="label">Partner sinds:</div>
                    <div class="field">{{partnerControlValue("dateOfPartnership")}}</div>
                </div>
            </div>
            <div class="section">
                <div class="info-field">
                    <div class="label">Achternaam:</div>
                    <div class="field">{{partnerControlValue("surname")}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Geslacht:</div>
                    <div class="field">{{partnerControlValue("gender")}}</div>
                </div>
                <div class="info-field last">
                    <div class="label">Burgerservicenummer:</div>
                    <div class="field">{{partnerControlValue("bsn")}}</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let child of childFormGroups.controls; index as i" class="overview-section">
        <div class="title">Kind {{i+1}}</div>
        <div class="section-column">
            <div class="section">
                <div class="info-field">
                    <div class="label">Naam:</div>
                    <div class="field">{{getChildControlValue(i, "name")}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Geboortedatum:</div>
                    <div class="field">{{getChildControlValue(i, "birthdate")}}</div>
                </div>
            </div>
            <div class="section">
                <div class="info-field">
                    <div class="label">Achternaam:</div>
                    <div class="field">{{getChildControlValue(i, "surname")}}</div>
                </div>
                <div class="info-field">
                    <div class="label">Geslacht:</div>
                    <div class="field">{{getChildControlValue(i, "gender")}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
