import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  isMenuDown: boolean = false;
  constructor() { }

  toggleMenu(){
    this.isMenuDown = !this.isMenuDown;
  }
}
