<fieldset>
    <div *ngIf="fieldName!=''" class="input-label__container">
        <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf="isRequired" class="input-label__required-star"> *</span></div>
    </div>
    <div class="radio-field">
        <div class="options-group">
            <div *ngFor="let option of options.slice(0, divide[0]); index as i" class="radio-option">
                <input  type="radio"
                        [id]="uniqueId+option"
                        [value]="option"
                        [name]="uniqueId"
                        [checked]="option==value"
                        (change)="handleOptionClick(option)"/>
                <label [for]="uniqueId+option">{{option}}</label>
            </div>
            <app-text-field *ngIf="hasOpenField"
                            class="radio-optionalfield"
                            value="_"
                            placeholder="Anders, namelijk"
                            [value]="openFieldValue"
                            (valueChange)="setOpenFieldValue($event)"/>
        </div>
        <div class="options-group">
            <div *ngFor="let option of options.slice(divide[0], divide[1]); index as i" class="radio-option">
                <input  type="radio"
                        [id]="uniqueId+option"
                        [value]="option"
                        [checked]="option==value"
                        [name]="uniqueId"
                        (change)="handleOptionClick(option)"
                        />
                <label [for]="uniqueId+option">{{option}}</label>
            </div>
        </div>
    </div>
</fieldset>
<app-error *ngIf="showError()" [errors]="errors"/>
