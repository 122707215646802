import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { isPlatformWorkerApp } from '@angular/common';

export const authGuard: CanActivateFn = (route, state) => {
  let isLoggedIn = inject(AuthenticationService).isLoggedIn();
  let router = inject(Router);
  if(isLoggedIn){

    return true;
  }else{
    router.navigate(["/login"])
    return false;
  }
 
};
