<div class="application-wrapper">
    <app-header/>
    <router-outlet>
        <div class="dropdown-menu" *ngIf="menuService.isMenuDown">
            <a class="menu-button" href="/" (click)="menuButtonHandler()"> Home</a>
            <a class="menu-button" href="meer-weten" (click)="menuButtonHandler()"> Meer weten</a>
            <a class="menu-button" href="contact" (click)="menuButtonHandler()"> Contact</a>
            <a class="menu-button" href="faq" (click)="menuButtonHandler()"> Meestgestelde vragen</a>
            <a class="header-request-button" href="">Start uw aanvraag</a>
        </div>
    </router-outlet>
    <app-footer/>
</div>


<ng-template #desktopMenu>
    <ul class="header-list-menu">
        <li *ngFor="let page of pages" class="menu-button">
            <a [href]="page.url">{{page.title}}</a>
        </li>
        <li class="header-request">
            <a class="header-request-button" href="">Start uw aanvraag</a>
        </li>
    </ul>
</ng-template>
