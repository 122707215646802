import {Component, Input, OnInit} from '@angular/core';
import { DualoptionFieldComponent } from "../../../../SharedComponents/fields/dualoption-field/dualoption-field.component";
import { StepService } from '../../../../services/step/step.service';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RadioFieldComponent } from '../../../../SharedComponents/fields/radio-field/radio-field.component';
import { DateFieldComponent } from "../../../../SharedComponents/fields/date-field/date-field.component";
import { SelectFieldComponent } from "../../../../SharedComponents/fields/select-field/select-field.component";
import {FinancialSupportModel, WorkAbroadPeriodModel} from "../../../../models/applicant.model";

@Component({
    selector: 'app-step6',
    standalone: true,
    templateUrl: './step6.component.html',
    styleUrl: '../../step.component.css',
    imports: [DualoptionFieldComponent, ReactiveFormsModule, CommonModule, RadioFieldComponent, DateFieldComponent, SelectFieldComponent]
})

export class Step6Component implements OnInit{
    @Input() data: FinancialSupportModel[] | undefined;
    public String = String;
    public options: string[] = ["WW-uitkering", "Bijstandsuitkering", "Arbeidsongeschiktheidsuitkering", "Pensioen"]
    constructor(public stepService: StepService, public formService: FormModelService){}

    ngOnInit(): void {
        this.stepService.setStep6Form(this.data);
    }

    public setReceivesSupport(value:string){
        if(value=="Ja"){
            // this.formService.addFinancialSupportForm()
        }else{
            this.formService.removeAllFinancialSupportForms();
        }
    }
    get receivesSupport():boolean{
        return this.formService.financialSupportForm.get("receivesSupport")?.value=="Ja";
    }
}
