import {Injectable} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    NgControl,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class FormModelService {

    applicationForm: FormGroup;                     // Stap 1
    applicantForm: FormGroup;                       // Stap 2
    partnerForm: FormGroup|null;                    // Stap 2
    childForm : FormGroup;                          // Stap 3  TODO: use formgroup (same concept as PeriodForm)
    PeriodForm: FormGroup;                          // Stap 4
    partnerPeriodForm: FormGroup|null;              // Stap 5 Step does not exist if applicant is single(extracted from step1)
    financialSupportForm: FormGroup;                // Stap 6
    partnerFinancialSupportForm: FormGroup|null;    // Stap 7 Step does not exist if applicant is single(extracted from step1)
    otherFinancialSupportForm: FormGroup;           // Stap 8
    consentForm: FormGroup;                         // Stap 9

    contactForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.applicationForm = this.createApplicationForm();
        this.applicantForm = this.createApplicantForm();
        this.partnerForm = null;
        this.childForm = this.creatChildFormArray();
        this.PeriodForm = this.createWorkAbroadForm();
        this.partnerPeriodForm = null
        this.financialSupportForm = this.createFinancialSupportForms();
        this.partnerFinancialSupportForm = null;
        this.otherFinancialSupportForm = this.createOtherFinancialSupportForm()
        this.consentForm = this.createConsentForm();
        this.contactForm = this.createContactForm();
    }

    getErrorMsg(control:NgControl, value=0):string[]{
        let errors: string[] = [];
        if(control.hasError("required")){
            errors.push("Dit veld is verplicht")
        }
        if(control.hasError("email")){
            errors.push("Ongeldig e-mailadres")
        }
        if(control.hasError("pattern")){
            errors.push("Ongeldig invoer")
        }
        if(control.hasError("min")){
            errors.push("Minimale waarde is "+value)
        }
        if(control.hasError("max")){
            errors.push("Maximale waarde is "+value)
        }
        if(control.invalid && errors.length==0){
            errors.push("Ongeldig invoer...")
        }
        return errors;
    }

    createConsentForm(): FormGroup {
        return this.fb.group({
            conditionsConsent: ["", Validators.required],
            authorizationConsent: ["", Validators.required],
        })
    }
    createContactForm(): FormGroup{
        return this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            emailConfirmation: ['', [Validators.required, Validators.email]],
            phonenumber: ['', [Validators.required, Validators.pattern(/^(\+)?[0-9]+/)]],
            message: ['', [Validators.required]]
        }, {validators: [this.emailMatchValidator]});
    }


    emailMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>{
        const email = control.get("email")?.value;
        const emailConfirmation = control.get("emailConfirmation")?.value;

        if(email != emailConfirmation){
            return {emailMismatch: true}
        }
        return null;
    };

    createApplicationForm(): FormGroup {
        return this.fb.group({
            reason: ['', Validators.required],
            situationStartDate: ['', Validators.required],
            hasPartner: ['', Validators.required],
        });
    }

    get applicantHasPartner():boolean{
        let value  = this.applicationForm.get("hasPartner")?.value;
        return value == "Ja, mijn partner woont op hetzelfde adres"
            || value == "Ja, mijn partner woont op een ander adres";
    }

    setApplicantPartner(val: string){
        if(val =="Nee, ik ben alleenstaand" || val == "" || val==" "|| val==null){
            this.partnerForm = null;
            this.partnerPeriodForm = null;
            this.partnerFinancialSupportForm = null;
        }else{
            console.log("Partner form created:", val);
            this.partnerForm = this.partnerForm ?? this.createPartnerForm();
            this.partnerPeriodForm = this.partnerPeriodForm ?? this.createWorkAbroadForm();
            this.partnerFinancialSupportForm = this.partnerFinancialSupportForm ?? this.createFinancialSupportForms();

            if(val =="Ja, mijn partner woont op een ander adres"){
                this.partnerForm.setControl("address", this.createPartnerAddressForm());
            }
        }
    }

    createApplicantForm(): FormGroup {
        return this.fb.group({
            name: ['', Validators.required],
            initials: [''],
            middleName: [''],
            surname: ['', Validators.required],
            birthdate: ['', Validators.required],
            bsn: ['', [Validators.required, Validators.pattern("^[0-9]{9}$")]],
            email: ['', [Validators.required, Validators.email]],
            phonenumber: ['', [Validators.required, Validators.pattern(/^(\+)?[0-9]+/)]],
        });
    };

    createPartnerForm(): FormGroup {
        return this.fb.group({
            name: ['', Validators.required],
            initials: [''],
            middleName: [''],
            surname: ['', Validators.required],
            birthdate: ['', Validators.required],
            birthplace: ['', Validators.required],
            birthcountry: ['', Validators.required],
            nationality: ['', Validators.required],
            gender: ['', Validators.required],
            bsn: ['', Validators.required],
            ssn: [''],
            isRegistredPartner: [false, Validators.required],
            dateOfPartnership: [''],
            address: null
        });
    }
    getPartnerAddressForm(): FormGroup{
        return this.partnerForm?.get("address") as FormGroup
    }
    createPartnerAddressForm(): FormGroup {
        return this.fb.group({
            street: ['', Validators.required],
            number: ['', Validators.required],
            postCode: ['', Validators.required],
            place: ['', Validators.required],
            country: ['', Validators.required],
            ssn: [''],
            locationDescription: ['']
        });
    }
    get partnerLivesAbroad():boolean {
        let country = this.getPartnerAddressForm().get("country") as FormControl;
        return country.value!="Nederland" && country.value!="" ;
    }

    // false: sameAddress --> form leeg
    setPartnerHasSameAddress(val: string ):boolean{
        if(val=="Ja, mijn partner woont op hetzelfde adres"){
/*
            this.partnerForm?.setControl("address", this.fb.group({}));
*/
            return true;
        }
/*
        this.partnerForm?.setControl("address", this.createpartnerAddressForm());
*/
        return false;
    }
    partnerHasSameAddress():boolean{
        let partnerResidenceValue = this.applicationForm.get("hasPartner")?.value;
        return this.setPartnerHasSameAddress(partnerResidenceValue);
    }

    createWorkAbroadForm(): FormGroup {
        return this.fb.group({
            workedAbroad: ['', Validators.required],
            periodForms: this.fb.array([])
        },
        {validators:this.WorkAbroadFormValidator});
    }

    createWorkCountryForm(): FormGroup {
    return this.fb.group({
            country: ['', Validators.required],
            percentage: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
        });
    };

    createFinancialSupportForms(): FormGroup {
        return this.fb.group({
            receivesSupport: ['', Validators.required],
            financialSupportForms: this.fb.array([])
        },
        {validators:this.FinancialSupportFormValidator});
    }

    createFinancialSupportForm(): FormGroup {
    return this.fb.group({
            financialSupportType: ['', Validators.required],
            startDate: ['', Validators.required],
            country: ['', Validators.required],
        })
    }

    createOtherFinancialSupportForm(): FormGroup {
        return this.fb.group({
            iban: ['', Validators.required],//
            bankCountry: ['', Validators.required],//
            hasReceivedAid: ['', Validators.required],
            aidRegistrationNumber: ['', Validators.required],//
            hasPartnerReceivedAid: ['', Validators.required],
            partnerAidRegistrationNumber: ['', Validators.required],//
            hasOtherAidSource: ['', Validators.required],
            aidOrganizationSource: ['', Validators.required],//
            aidCountrySource: ['', Validators.required],//
            startDate: ['', Validators.required],//
        })
    }

    setHasReceivedAid(val: string){
        if(val==undefined || val=="")
            return;

        let aidRegistrationNumber = this.otherFinancialSupportForm.get("aidRegistrationNumber") as FormControl
        if(val=="Ja")
            aidRegistrationNumber.setValidators([Validators.required]);
        else
            aidRegistrationNumber.clearValidators();

        aidRegistrationNumber.updateValueAndValidity();
    }

    setApplicantReceivesSupport(val: boolean){
        this.financialSupportForm.get("receivesSupport")?.setValue(val? "Ja":"Nee");
    }

    setPartnerReceivesSupport(){
        let val = this.partnerFinancialSupportForms.length > 0;
        this.partnerFinancialSupportForm?.get("receivesSupport")?.setValue(val? "Ja":"Nee");
    }

    showReceivedAid(){
        return this.otherFinancialSupportForm?.get("hasReceivedAid")?.value == "Ja";
    }

    setPartnerReceivedAid(val: string){
        if(val==undefined || val=="")
            return;

        let partnerAidRegistrationNumber = this.otherFinancialSupportForm.get("partnerAidRegistrationNumber") as FormControl;
        if(val=="Ja")
            partnerAidRegistrationNumber.setValidators([Validators.required]);
        else
            partnerAidRegistrationNumber.clearValidators();

        partnerAidRegistrationNumber.updateValueAndValidity();
    }
    showPartnerAidRegistrationNumber(){
        return this.otherFinancialSupportForm?.get("hasPartnerReceivedAid")?.value == "Ja";
    }

    setHasOtherSource(val: string){
        if(val==undefined || val=="")
            return;

        let aidOrganizationSource = this.otherFinancialSupportForm.get("aidOrganizationSource") as FormControl;
        let aidCountrySource = this.otherFinancialSupportForm.get("aidCountrySource") as FormControl;
        let startDate = this.otherFinancialSupportForm.get("startDate") as FormControl;
        if(val=="Ja"){
            aidOrganizationSource.setValidators([Validators.required]);
            aidCountrySource.setValidators([Validators.required]);
            startDate.setValidators([Validators.required]);
        }else{
            aidOrganizationSource.clearValidators();
            aidCountrySource.clearValidators();
            startDate.clearValidators();
        }

        aidOrganizationSource.updateValueAndValidity();
        aidCountrySource.updateValueAndValidity();
        startDate.updateValueAndValidity();
    }

    setHasPartnerReceivedAidYesNo(){
        let val = this.otherFinancialSupportForm.get("partnerAidRegistrationNumber")?.value != "";
        this.otherFinancialSupportForm.get("hasPartnerReceivedAid")?.setValue(val? "Ja":"Nee");
    }

    setHasReceivedAidYesNo(){
        let val = this.otherFinancialSupportForm.get("aidRegistrationNumber")?.value != "";
        this.otherFinancialSupportForm.get("hasReceivedAid")?.setValue(val? "Ja":"Nee");
    }

    setHasOtherFincancialSourceYesNo(){
        let val = this.otherFinancialSupportForm.get("aidOrganizationSource")?.value != "";
        this.otherFinancialSupportForm.get("hasOtherAidSource")?.setValue(val? "Ja":"Nee");
    }


    showOtherSource(){
        return this.otherFinancialSupportForm?.get("hasOtherAidSource")?.value == "Ja";
    }

    //Children Form operations

    get childForms(): FormArray{
        return this.childForm.get("childForms") as FormArray;
    };
    addChildForm(){
        this.childForms.push(this.createChildForm());
    }
    removeChildForm(index:number){
        this.childForms.removeAt(index);
    }
    removeAllChildForms(){
        this.childForms.clear();
    }
    get otherCountries(): FormArray{
        return this.createWorkAbroadPeriodForm().get('otherCountriesList') as FormArray;
    };
    creatChildFormArray(): FormGroup {
        return this.fb.group({
            childForms: this.fb.array([])
        });
    }
    createChildForm():FormGroup{
        return this.fb.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            middleName: [''],
            birthdate: ['', Validators.required],
            gender: ['', Validators.required],
            otherPartnerOfChild: ['', Validators.required],
            otherParent: this.createOtherParent(),
            partnerWorksAbroad: ['', Validators.required],
            partnerWorkCountry: ['', Validators.required],
            otherPartnerWorkedAbroad: ['', Validators.required],
            otherPartnerWorkCountry: ['', Validators.required],
            residence: ["", Validators.required]
        })
    }

    setOtherKnownParent(val: string, index: number){
        if(val==undefined || val=="")
            return;

        let childForm = this.childForms.at(index) as FormGroup;
        let otherParentGroup = childForm.get("otherParent") as FormGroup;

        if(val=="Iemand anders, namelijk.."){

            Object.keys(otherParentGroup.controls).forEach( key =>{
                const control = otherParentGroup?.get(key) as FormControl;
                (key!="initials" && key!="middleName") && control.setValidators([Validators.required]);
                control.updateValueAndValidity();
            })
        }
        else{
            Object.keys(otherParentGroup.controls).forEach( key =>{
                const control = otherParentGroup?.get(key) as FormControl;
                control.setValidators([])
                control.updateValueAndValidity();
            })
        }

        otherParentGroup.updateValueAndValidity();
    }

    showOtherPartnerOfChild(index:number){
        let childForm = this.childForms.at(index) as FormGroup;
        let otherParentGroup = childForm.get("otherParent") as FormGroup;
        const isRequired = otherParentGroup.get("name")?.hasValidator(Validators.required);
        const show = childForm?.get("otherPartnerOfChild")?.value == "Iemand anders, namelijk.." && isRequired!=null? isRequired:false
        return show;
    }

    setPartnerWorksAbroad(val: string, index: number){
        if(val==undefined || val=="")
            return;

        let childForm = this.childForms.at(index) as FormGroup;
        let partnerWorkCountry = childForm.get("partnerWorkCountry") as FormControl;
        if(val=="Ja")
            partnerWorkCountry.setValidators([Validators.required])
        else
            partnerWorkCountry.clearValidators();

        partnerWorkCountry.updateValueAndValidity();
    }

    showPartnerWorkCountry(index:number){
        let childForm = this.childForms.at(index) as FormGroup;
        return childForm?.get("partnerWorksAbroad")?.value == "Ja";
    }

    setOtherPartnerWorkedAbroad(val: string, index: number){
        if(val==undefined || val=="")
            return;

        let childForm = this.childForms.at(index) as FormGroup;
        let otherPartnerWorkCountry = childForm.get("otherPartnerWorkCountry") as FormControl;
        if(val=="Ja")
            otherPartnerWorkCountry.setValidators([Validators.required])
        else
            otherPartnerWorkCountry.clearValidators();

        otherPartnerWorkCountry.updateValueAndValidity();
    }

    showOtherPartnerWorkCountry(index:number){
        let childForm = this.childForms.at(index) as FormGroup;
        return childForm?.get("otherPartnerWorkedAbroad")?.value == "Ja";
    }

    createOtherParent():FormGroup{
        return this.fb.group({
            name: ['', Validators.required],
            initials: ['',],
            middleName: ['',],
            surname: ['', Validators.required],
            birthdate: ['', Validators.required],
            birthplace: ['', Validators.required],
            birthcountry: ['', Validators.required],
            bsn: ['', Validators.required],
        })
    }

    //Period form operations
    createWorkAbroadPeriodForm(): FormGroup {
        return this.fb.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            country: ['', Validators.required],
            ssn: ['', [Validators.required]],
            employmentType: ['', [Validators.required]],
            employer: ['',this.employmentDataValidator("Loondienst")],
            tradeName: ['', this.employmentDataValidator("Zelfstandige")],
            workedInOtherCountries: ['', Validators.required],
            otherCountriesList: this.fb.array([])
        });
    };
    get periodForms(): FormArray{
        return this.PeriodForm.get('periodForms') as FormArray;
    }
    addPeriodForm(){
        this.periodForms.push(this.createWorkAbroadPeriodForm());
    }
    removePeriodForm(index:number){
        this.periodForms.controls.splice(index, 1);
    }
    removeAllPeriodForms(){
        this.periodForms.clear();
    }
    isSelfEmployed(index: number): boolean{
        let periodForm = this.periodForms.at(index);
        return periodForm.get("employmentType")?.value=="Zelfstandige";
    }
    public isEmployed(index: number): boolean{
        let periodForm = this.periodForms.at(index);
        return periodForm.get("employmentType")?.value=="Loondienst";
    }

    employmentDataValidator(type: string): ValidatorFn{
        return (control: AbstractControl): ValidationErrors | null =>{
            const employmentType = (control.parent?.get("employmentType") as FormControl)?.value;
            if(employmentType==type && control.value=="")
                return {required:true}
            return null;
        }
    }

    //PARTNER
    get partnerPeriodForms(): FormArray{
        return this.partnerPeriodForm?.get('periodForms') as FormArray;
    }
    addPartnerPeriodForm(){
        this.partnerPeriodForms.push(this.createWorkAbroadPeriodForm());
    }
    removePartnerPeriodForm(index:number){
        this.partnerPeriodForms.controls.splice(index, 1);
    }
    removeAllPartnerPeriodForms(){
        this.partnerPeriodForms.clear();
    }
    getPartnerOtherCountries(index:number): FormArray{
    let formgGroup = this.partnerPeriodForms.at(index) as FormGroup;
        return formgGroup.get("otherCountriesList") as FormArray;
    }
    addPartnerOtherCountry(index:number){
        if(this.partnerPeriodForms.length==0){
            this.partnerPeriodForm = this.createWorkAbroadPeriodForm();
        }
        this.getPartnerOtherCountries(index).push(this.createWorkCountryForm())
    }
    removePartnerOtherCountry(periodIndex:number, countryIndex:number){
        let countriesArray= this.getPartnerOtherCountries(periodIndex);
        countriesArray.removeAt(countryIndex);
    }
    resetPartnerOtherCountries(formGroup: AbstractControl){
        let otherCountries = formGroup.get('otherCountriesList') as FormArray;
        otherCountries.clear()
    }
    getPartnerOtherCountriesControls(index:number){
        return this.getPartnerOtherCountries(index).controls;
    }
    getPartnerWorkedInOtherCountry(index:number): string{
        let formGroup = this.partnerPeriodForms.at(index) as FormGroup;
        return formGroup.get("workedInOtherCountries")?.value;
    }
    isPartnerSelfEmployed(index: number): boolean{
        let periodForm = this.partnerPeriodForms.at(index);
        return periodForm.get("employmentType")?.value=="Zelfstandige";
    }
    public isPartnerEmployed(index: number): boolean{
        let periodForm = this.partnerPeriodForms.at(index);
        return periodForm.get("employmentType")?.value=="Loondienst";
    }

    //Financial support form operations
    get financialSupportForms(): FormArray {
        return this.financialSupportForm.get('financialSupportForms') as FormArray;
    }
    addFinancialSupportForm(){
        this.financialSupportForms.push(this.createFinancialSupportForm());
    }
    removeFinancialSupportForm(index:number){
        this.financialSupportForms.removeAt(index);
    }
    removeAllFinancialSupportForms(){
        this.financialSupportForms.clear();
    }

    //Partner financial support form operations
    get partnerFinancialSupportForms(): FormArray {
        return this.partnerFinancialSupportForm?.get('financialSupportForms') as FormArray ?? this.fb.array([]);
    }
    addPartnerFinancialSupportForm(){
/*        if(this.partnerFinancialSupportForms.length==0){
            this.partnerFinancialSupportForm = this.createFinancialSupportForms();
        }*/
        this.partnerFinancialSupportForms.push(this.createFinancialSupportForm());
    }
    removePartnerFinancialSupportForm(index:number){
        this.partnerFinancialSupportForms.removeAt(index);
    }
    removeAllPartnerFinancialSupportForms(){
        this.partnerFinancialSupportForms.clear();
    }

    getOtherCountries(index:number): FormArray{
        let formgGroup = this.periodForms.at(index) as FormGroup;
        return formgGroup.get("otherCountriesList") as FormArray;
    }

    setApplicantWorkedAbroad(val: boolean){
        this.PeriodForm.get("workedAbroad")?.setValue(val? "Ja":"Nee");
    }

    addOtherCountry(index:number){
        this.getOtherCountries(index).push(this.createWorkCountryForm())
    }

    removeOtherCountry(periodIndex:number, countryIndex:number){
        let countriesArray= this.getOtherCountries(periodIndex);
        countriesArray.removeAt(countryIndex);
    }

    resetOtherCountries(formGroup: AbstractControl){
        let otherCountries = formGroup.get('otherCountriesList') as FormArray;
        otherCountries.clear()
    }

    getOtherCountriesControls(index:number){
    return this.getOtherCountries(index).controls;
    }

    getWorkedInOtherCountry(index:number): string{
        let formgGroup = this.periodForms.at(index) as FormGroup;
        return formgGroup.get("workedInOtherCountries")?.value;
    }

    setPartnerWorkedAbroad(){
        let val = this.partnerPeriodForms.length>0;
        this.partnerPeriodForm?.get("workedAbroad")?.setValue(val? "Ja":"Nee");
    }


    // Validators
    customValidator(name: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const forbidden = name=="test";
            return forbidden ? { forbiddenName: { value: control.value } } : null;
        };
    }
    WorkAbroadFormValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const workedAbroadValue = control.get("workedAbroad") as FormControl;
            const workedAbroadFormRequired = workedAbroadValue.value == "Ja";
            return workedAbroadFormRequired ? {PeriodRequired: {value: workedAbroadValue}} : null;
        };
    }


    FinancialSupportFormValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const receivesSupportValue = control.get("receivesSupport") as FormControl;
            const workedAbroadFormRequired = receivesSupportValue.value == "Ja";
            return workedAbroadFormRequired ? {PeriodRequired: {value: receivesSupportValue}} : null;
        };
    }
}
