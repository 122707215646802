<div class="privacy-page">
    <div class="privacy-section">
        <div class="privacy-container">
            <h1>Privacyverklaring - Mijn Bijslag</h1>

            <p>Dit privacybeleid is opgesteld door Mijn Bijslag en is tevens te raadplegen op onze website: <a href="https://www.mijn-bijslag.nl">www.mijn-bijslag.nl</a>.</p>

            <p>Mijn Bijslag, een handelsnaam van Mijn Subsidie B.V., gevestigd op Ampèrestraat 21 b, 3861 NC Nijkerk, Nederland, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>

            <p>Bij Mijn Bijslag begrijpen we hoe belangrijk de privacy van onze klanten is en daarom nemen we de bescherming van persoonsgegevens zeer serieus. In deze privacyverklaring leggen we uit welke persoonsgegevens we verzamelen, hoe wij deze opslaan, hoe we deze gebruiken en hoe u deze kunt verwijderen of laten wijzigen.</p>

            <p>De privacyverklaring is voor het laatst gewijzigd op 29-Juli 2023. Mijn Bijslag is bevoegd om deze policy ten alle tijden te wijzigen. Als klant wordt u verwacht de verklaring regelmatig te lezen. Mocht u het niet eens zijn met deze veranderingen dan kunt u altijd contact opnemen via de contactgegevens onderaan deze verklaring.</p>

            <section>
                <h2>1. Contactgegevens</h2>
                <p>
                    Website: <a href="https://www.mijnbijslag.nl">www.mijnbijslag.nl</a><br>
                    Adres: Verdunplein 17, 5627 SZ Eindhoven, Nederland<br>
                    Telefoon: +31850022358<br>
                    E-mail: <a href="mailto:info@mijn-bijslag.nl">info&#64;mijn-bijslag.nl</a>
                </p>
            </section>

            <section>
                <h2>2. Verzamelen van gegevens</h2>
                <p>Mijn Bijslag verzamelt alleen persoonsgegevens die nodig zijn om onze diensten aan onze klanten te kunnen leveren. Dit kan onder andere gaan om:</p>
                <ul>
                    <li>Naam</li>
                    <li>Adres</li>
                    <li>E-mailadres</li>
                    <li>Telefoonnummer</li>
                    <li>Geboortedatum</li>
                    <li>Geslacht</li>
                    <li>Nationaliteit</li>
                    <li>BSN-nummer</li>
                    <li>Andere gegevens die relevant zijn voor onze dienstverlening</li>
                </ul>
                <p>Deze gegevens worden verzameld via onze website of per contact via e-mail.</p>
            </section>

            <section>
                <h2>3. Gebruik van gegevens</h2>
                <p>Wij gebruiken de verzamelde persoonsgegevens voor het leveren van onze diensten. Bijvoorbeeld om contact met u op te nemen over nieuwe subsidiemogelijkheden, of om u te helpen bij het invullen van een subsidieaanvraag.</p>
                <p>Daarnaast analyseert Mijn Bijslag uw gedrag op onze website om deze te optimaliseren en om inzicht te krijgen in het gebruik van onze diensten. Hiervoor maken we gebruik van Google Analytics. De informatie die we hiermee verzamelen, wordt geanonimiseerd en is niet herleidbaar tot individuele personen.</p>
            </section>

            <section>
                <h2>4. Delen van uw persoonsgegevens</h2>
                <p>Wij delen uw persoonsgegevens niet met derden, tenzij dit nodig is voor het uitvoeren van onze diensten of wanneer we hier wettelijk toe verplicht zijn. Bijvoorbeeld bij delen met overheidsinstanties om werkzaamheden te voltooien.</p>
            </section>

            <section>
                <h2>5. Beveiliging van uw gegevens</h2>
                <p>Mijn Bijslag neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a href="mailto:info@mijn-bijslag.nl">info&#64;mijn-bijslag.nl</a>.</p>
            </section>

            <section>
                <h2>6. Wijzigen of verwijderen van de gegevens</h2>
                <p>Als klant kunt u de door u verstrekte gegevens inzien, wijzigen en/of verwijderen. Dit kunt u doen door contact met ons op te nemen via het contactformulier of via <a href="mailto:info@mijn-bijslag.nl">info&#64;mijn-bijslag.nl</a>. U kunt ook navraag en inzicht verkrijgen in de door ons opgeslagen gegevens.</p>
            </section>
        </div>

    </div>
</div>
