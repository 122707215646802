<div *ngIf="fieldName!=''" class="input-label__container">
    <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf="isRequired" class="input-label__required-star"> *</span></div>
</div>
<fieldset>
    <div class="radio-field">
        <div class="Yesno-group">
            <div class="radio-option">
                <input  type="radio"
                        [id]="uniqueId+'_1'"
                        [name]="uniqueId"
                        [value]="duoOption[0]"
                        [checked]="value==duoOption[0]"
                        (change)="handleNewValue(duoOption[0])"/>
                <label [for]="uniqueId+'_1'">{{duoOption[0]}}</label>
            </div>
            <div class="radio-option">
                <input  type="radio"
                            [id]="uniqueId+'_2'"
                            [name]="uniqueId"
                            [value]="duoOption[1]"
                            [checked]="value==duoOption[1]"
                            (change)="handleNewValue(duoOption[1])"/>
                <label [for]="uniqueId+'_2'">{{duoOption[1]}}</label>
            </div>
        </div>
    </div>
</fieldset>
<app-error *ngIf="showError()" [errors]="errors"/>


