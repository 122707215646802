<table>
    <tr>
        <th *ngFor="let column of columns">
            <button *ngIf="column=='Status'" class="filter" mat-button [matMenuTriggerFor]="menu">{{column}}</button>
            <div *ngIf="column!='Status'" >{{ column }}</div>
        </th>
    </tr>
    <ng-container *ngIf="isLoading">
        <ng-container *ngTemplateOutlet="skeleton"/>
    </ng-container>
    <tr *ngFor="let application of filteredApplications" (click)="handleApplicationClick(application)" [class]="getClass(application)">
        <td>{{getShorthandId(application.id)}}</td>
        <td>{{application.applicant!=null? [application.applicant?.name,application.applicant?.middleName,application.applicant?.surname].join(" "):"-"}}</td>
        <td>{{application.applicant!=null && application.applicant.initials!=""? application.applicant.initials: "-"}}</td>
        <td>{{application.applicant!=null? application.applicant?.email:"-"}}</td>
        <td>{{application.applicant!=null? application.applicant?.phonenumber: "-"}}</td>
        <td>
            <div *ngIf="application.applicant?.children != null && application.applicant.children.length > 0">
                {{application.applicant.children[0]!=null? [application.applicant.children[0]?.name,application.applicant.children[0]?.middlename,application.applicant.children[0]?.surname].join(" "):"-"}}
            </div>
        <td> {{application.totalChildBenefit!="0"? '€ '+Number(application.totalChildBenefit)/100: '-'}}</td>
        <td>{{application.creationDateTime | date:'dd-MM-yyyy HH:mm' }}</td>
        <td>
            <select [class]="getStateClass(application.state)" [(ngModel)]="application.state" (change)="updateApplicationState(application)" (click)="handleClick($event)">
                <option *ngFor="let status of applicationStatus " class="option">{{status}}</option>
            </select>
        </td>
    </tr>
</table>

<ng-template #skeleton>
    <tr *ngFor="let column of columns">
        <td *ngFor="let column of columns">
            <ngx-skeleton-loader [theme]="skeletonStyle" count="1"></ngx-skeleton-loader>
        </td>
    </tr>
</ng-template>


<mat-menu #menu="matMenu">
    <button *ngFor="let status of applicationStatus" mat-menu-item (click)="filterByStatus(status)">{{status}} ({{getCountForStatus(status)}})</button>
    <button mat-menu-item (click)="filterByStatus('Alles')">Alles ({{applications.length}})</button>
</mat-menu>
