export class PaymentReceipt
{
    clientSecret:string = ""
    amountsToReceive: AidForChild[] = [];
    totalAmountToReceive: number = 0;
    totalPrice: number = 0;
    pricePerChild: number = 0;
}

class AidForChild {
    name: string = "";
    age: number = 0;
    amountToReceive: number = 0;
}


