<div class="date-field">
    <div *ngIf="fieldName!=''" class="input-label__container">
        <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf="isRequired" class="input-label__required-star"> *</span></div>
    </div>
    <div class="input">
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-select (selectionChange)="setSelectedYear($event)" [value]="SelectedYear" placeholder="Jaar" style="font-size: .875rem;">
                <mat-option style="background-color: #F6F6F6; flex:1" *ngFor="let year of Years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-select (selectionChange)="setSelectedMonth($event)" [value]="SelectedMonth" placeholder="Maand" style="font-size: .875rem;">
                <mat-option style="background-color: #F6F6F6;flex:1" *ngFor="let month of Months" [value]="month">{{MonthStrings[Number(month)-1]}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-select (openedChange)="setDays($event)" (selectionChange)="setSelectedDay($event)" [value]="SelectedDay" placeholder="Dag" style="font-size: .875rem;">
                <mat-option style="background-color: #F6F6F6;flex:1" *ngFor="let day of Days" [value]="day">{{day}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

