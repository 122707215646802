<div class="step">
    <div class="field-group__container">
        <form [formGroup]="formService.otherFinancialSupportForm" class="form-container">
            <div class="field-group__container">
                <div class="field-group__header">Kinderbijslag ontvangen</div>
                <div class="field-group">
                    <app-text-field fieldName="Rekeningnummer waarop u de kinderbijslag wilt ontvangen"
                                    placeholder="Bijvoorbeeld NL99BANK0123456789"
                                    formControlName="iban"
                                    style="width: 60%"
                                    [submitted]="stepService.stepSubmitted[7]"
                                    />
                    <app-select-field fieldName="Vestigingsland van uw bank"
                                      formControlName="bankCountry"
                                      style="width: 50%"
                                      [submitted]="stepService.stepSubmitted[7]"/>
                </div>
            </div>
            <div class="field-group__container">
                <div class="field-group__header">Eerdere of andere vergoedingen</div>
                <div class="field-group">
                    <app-dualoption-field fieldName="Heeft u al eerder kinderbijslag ontvangen uit Nederland?"
                                          [duoOption]="['Ja', 'Nee']"
                                          formControlName="hasReceivedAid"
                                          (inputChange)="formService.setHasReceivedAid($event)"
                                          [submitted]="stepService.stepSubmitted[7]"/>
                    <app-text-field *ngIf="formService.showReceivedAid()"
                                    fieldName="Met registratienummer"
                                    placeholder="Registratienummer"
                                    formControlName="aidRegistrationNumber"
                                    [submitted]="stepService.stepSubmitted[7]"
                                    matTooltip="Als u deze niet meer weet vul een vraagteken in"
                                    matTooltipPosition="above"
                                    matTooltipHideDelay="100"/>
                    <app-dualoption-field fieldName="Heeft uw partner al eerder kinderbijslag ontvangen uit Nederland?	"
                                          [duoOption]="['Ja', 'Nee']"
                                          formControlName="hasPartnerReceivedAid"
                                          (inputChange)="formService.setPartnerReceivedAid($event)"
                                          [submitted]="stepService.stepSubmitted[7]"/>
                    <app-text-field *ngIf="formService.showPartnerAidRegistrationNumber()"
                                    fieldName="Met registratienummer"
                                    placeholder="Registratienummer"
                                    formControlName="partnerAidRegistrationNumber"
                                    [submitted]="stepService.stepSubmitted[7]"
                                    matTooltip="Als u deze niet meer weet vul een vraagteken in"
                                    matTooltipPosition="right"
                                    matTooltipHideDelay="100"/>
                    <app-radio-field fieldName="Ontvangt u of iemand anders voor uw kind een vergoeding van een andere instantie in Nederland of buiten Nederland?"
                                     [options]="['Nee', 'Nee, maar ik of iemand anders heeft een vergoeding aangevraagd','Ja']"
                                     formControlName="hasOtherAidSource"
                                     (inputChange)="formService.setHasOtherSource($event)"
                                     [submitted]="stepService.stepSubmitted[7]"/>
                    <app-text-field *ngIf="formService.showOtherSource()"
                                    fieldName="Van welke instantie ontvangt u of iemand anders voor uw kind een vergoeding?"
                                    placeholder="Naam instantie"
                                    formControlName="aidOrganizationSource"
                                    [submitted]="stepService.stepSubmitted[7]"
                                    matTooltip="Als u deze niet meer weet vul een vraagteken in"
                                    matTooltipPosition="right"
                                    matTooltipHideDelay="100"
                                    style="width: fit-content"/>
                    <app-select-field *ngIf="formService.showOtherSource()"
                                      fieldName="Uit welk land ontvangt u of iemand anders een vergoeding?"
                                      formControlName="aidCountrySource"
                                      [submitted]="stepService.stepSubmitted[7]"/>
                    <app-date-field  *ngIf="formService.showOtherSource()"
                                     fieldName="Sinds wanneer ontvangt u of iemand anders de vergoeding?"
                                     formControlName="startDate"
                                     style="width: fit-content"
                                     [submitted]="stepService.stepSubmitted[7]"/>
                </div>
            </div>
        </form>
    </div>
</div>
