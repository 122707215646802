import { Injectable } from '@angular/core';
import { FormModelService } from '../formModel/form-model.service';
import { DataService } from '../data/data.service';
import { StripeService } from '../stripe/stripe.service';
import {Observable} from "rxjs";
import {PaymentReceipt} from "../../models/payment.model";
import { environment } from '../../../environments/environment'
import {
    AggregatedApplicationModel,
    ApplicantModel,
    ApplicationModel,
    ChildModel, FinancialSupportModel,
    PartnerModel, PreviousFinancialSupportModel,
    WorkAbroadPeriodModel
} from "../../models/applicant.model";
import {FormArray} from "@angular/forms";
@Injectable({
  providedIn: 'root'
})
export class StepService {
    stepNumber: number = environment.production? 1: 1;

    public lastStep = 10;
    public application:any = {};
    private applicationSubmitted: boolean = false; // prevents that multiple next clicks on step 9 result to skip payment
    public isNextButtonLoading = false;
    public applicationId: string = "";

    //TODO: implement mechanism of preventing skipping steps by multiple click on next button ( same as applicationsubmitted)
    public stepSubmitted: boolean[] = [false, false, false, false, false, false, false, false, false, false];

    constructor(
        private formService: FormModelService,
        private dataService: DataService,
        private stripeService: StripeService
    ){}

    public nextStep(){
        this.stepSubmitted[this.stepNumber-1] = true;
        if(this.isStepValid(this.stepNumber)){
            if(this.stepNumber<=8){
                let reponse = this.sendData(this.stepNumber);
                if(!reponse){
                    console.error("Error: Data could not be sent!");
                    return;
                }
            }
            else if(this.stepNumber==10){
                this.handlePayment();
            }
            this.increaseStepNumber();
        }else{
            console.log("Step", this.stepNumber, "INVALID");
            console.log("Partner:", this.formService.partnerForm==null)
        }
    }

    increaseStepNumber(): void{
        if(this.stepNumber<this.lastStep){
            this.stepNumber+=1
            if(!this.formService.applicantHasPartner && (this.stepNumber==5||this.stepNumber==7)){
                this.stepNumber+=1;
            }
        }
    }
    public async sendData(stepNumber:number):Promise<boolean>{
        switch (stepNumber){
            case 1:
                console.log(this.formService.applicationForm.value as ApplicationModel)
                let applicationId = await this.dataService.createInitialApplication(
                    this.formService.applicationForm.value as ApplicationModel, stepNumber, this.applicationId)
                    .toPromise() ?? "";
                this.stripeService.applicationId = applicationId;
                console.log("ApplicationId: ", applicationId);
                this.applicationId = applicationId;
                return applicationId != "";
            case 2:
                console.log(this.formService.applicantForm.value as ApplicantModel)
                await this.dataService.updateApplicant(
                    this.formService.applicantForm.value as ApplicantModel,
                    this.applicationId, stepNumber).toPromise() ;

                let model = this.formService.partnerForm?.value as PartnerModel;
                console.log(model);
                (model != null ) && await this.dataService.updatePartner(
                    model,
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 3:
                console.log(3, this.formService.childForms.value as ChildModel[])
                await this.dataService.updateChildren(
                    this.formService.childForms.value as ChildModel[],
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 4:
                await this.dataService.updateApplicantWorkAbroadPeriods(
                    this.formService.periodForms.value as WorkAbroadPeriodModel[],
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 5:
                let periodModel = this.formService.partnerPeriodForms.value as WorkAbroadPeriodModel[];
                console.log(periodModel);
                (periodModel != null) && await this.dataService.updatePartnerWorkAbroadPeriods(
                    periodModel,
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 6:
                console.log(this.formService.financialSupportForms.value);
                await this.dataService.updateFinancialSupport(
                    this.formService.financialSupportForms.value as FinancialSupportModel[],
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 7:
                let supportModel =this.formService.partnerFinancialSupportForms.value;
                console.log(supportModel);
                (supportModel != null) && await this.dataService.updatePartnerFinancialSupport(
                    supportModel,
                    this.applicationId, stepNumber).toPromise();
                return true;
            case 8:
                console.log(this.formService.otherFinancialSupportForm.value);
                await this.dataService.updatePreviousSupport(
                    this.formService.otherFinancialSupportForm.value as PreviousFinancialSupportModel,
                    this.applicationId, stepNumber).toPromise();
                return true;
            default:
                return false;
        }
    }

    public previousStep(): void{
        if(this.stepNumber>1){
            this.stepNumber--;
            if((this.stepNumber==5||this.stepNumber==7) && !this.formService.applicantHasPartner){
                this.stepNumber--;
            }
        }
    }

    public aggregate(): void{
        let applicant: any = {};
        let partner: any = {};
        this.application = this.formService.applicationForm.value;

        applicant = this.formService.applicantForm.value;
        applicant["WorkAbroadPeriods"] = this.formService.periodForms.value;
        applicant["children"] = this.formService.childForms.value;
        applicant["currentFinancialSupport"] = this.formService.financialSupportForms.value;

        let otherSupport = this.formService.otherFinancialSupportForm?.value;

        delete otherSupport.hasReceivedAid;
        delete otherSupport.hasPartnerReceivedAid;
        delete otherSupport.hasOtherAidSource;

        applicant["previousFinancialSupport"] = otherSupport

        applicant["WorkAbroadPeriods"] = [];
        for(let period of this.formService.periodForms.value){
            period.otherCountries = JSON.stringify(period.otherCountries);
            delete period["workedInOtherCountries"];
            applicant["WorkAbroadPeriods"].push(period);
        }
        this.application["applicant"] = applicant;



        partner = this.formService.partnerForm?.value;

        // modify WorkAbroadPeriods
        if(partner!=null){
            partner["WorkAbroadPeriods"] = [];
            for(let period of this.formService.partnerPeriodForms.value){
                period.otherCountries = JSON.stringify(period.otherCountries);
                delete period["workedInOtherCountries"];
                partner["WorkAbroadPeriods"].push(period);
            }
            partner["financialSupport"] = this.formService.partnerFinancialSupportForms.value;
            this.application["applicant"]["partner"] = partner;
        }
        console.log(this.application)
    }

    /*public submitApplication():Observable<void>  {
        return this.dataService.postApplication(this.application)
    }*/

    public handlePayment() {
        console.log("Handling payment");
        this.isNextButtonLoading = true;
        this.stripeService.handlePayment(this.formService.applicantForm.value.name+" "+this.formService.applicantForm.value.surname, this.formService.applicantForm.value.email, this.applicationId)
            .then(
                (success)=>{
                    this.isNextButtonLoading = false;
                    if(!success){
                        console.error("Handling payment has failed!!");
                    }
                })
    }


    isStepValid(stepNumber:number):boolean{
        switch(stepNumber){
            case 1: {
                return this.formService.applicationForm.valid;
            }
            case 2: {
                return this.formService.applicantForm.valid &&
                    (this.formService.partnerForm == null || this.formService.partnerForm.valid);
            }
            case 3: {
                return this.formService.childForm.valid;
            }
            case 4: {
                return this.formService.PeriodForm.valid;
            }
            case 5: {
                return ((this.formService.partnerPeriodForm!=null && this.formService.partnerPeriodForm.valid) ||
                    this.formService.partnerPeriodForm==null);
            }
            case 6: {
                return this.formService.financialSupportForm.valid;
            }
            case 7: {
                return ((this.formService.partnerFinancialSupportForm!=null && this.formService.partnerFinancialSupportForm.valid)
                    ||this.formService.partnerFinancialSupportForm==null);
            }
            case 8: {
                return this.formService.otherFinancialSupportForm.valid;
            }
            case 9: { // overview
                return true;
            }
            case 10: {
                return this.formService.consentForm.valid;
            }
            default:
                return false;
        }

    }

    public setStep1Form(data: ApplicationModel|null|undefined): void{
        if(data==null){
            return;
        }
        this.formService.applicationForm.setValue(data)
    }

    public setStep2Form(dataApplicant: ApplicantModel|null|undefined, dataPartner: PartnerModel|null|undefined): void{
        if(dataApplicant==null){
            return;
        }
        console.log("dataApplicant:", dataApplicant);
        this.formService.applicantForm.setValue(dataApplicant);
        if( dataPartner != null ){
            if(this.formService.partnerForm==null){
                this.formService.partnerForm = this.formService.createPartnerForm();
            }
            this.formService.partnerForm?.patchValue(dataPartner);
            console.log("partnerForm:", this.formService.partnerForm.value)
        }
    }

    public setStep3Form(data: ChildModel[]|null|undefined): void{
        if(data==null){
            return;
        }

        this.formService.childForms.clear();
        this.formService.childForm = this.formService.creatChildFormArray();
        for (let i = 0; i< data.length; i++) {
            let child = this.formService.createChildForm();
            child.setValue(data[i]);
            this.formService.childForms.push(child);
        }

    }

    public setStep4Form(data: WorkAbroadPeriodModel[]|null|undefined): void{
        if(data==null){
            return;
        }
        this.formService.periodForms.clear();
        this.formService.setApplicantWorkedAbroad(data.length != 0)
        for (let i = 0; i< data.length; i++) {
            let period = this.formService.createWorkAbroadPeriodForm();
            let otherCountries = data[i].otherCountriesList;
            data[i].otherCountriesList = [];
            period.setValue(data[i]);
            let countriesList = period.get('otherCountriesList') as FormArray;
            for(let country of otherCountries){
                let otherCountry = this.formService.createWorkCountryForm();
                otherCountry.setValue(country);
                countriesList.push(otherCountry);
            }
            this.formService.periodForms.push(period);
        }
    }

    public setStep5Form(data: WorkAbroadPeriodModel[]|null|undefined): void{
        if(data==null){
            return;
        }
        console.log("Setting step 5 form")
        this.formService.partnerPeriodForms.clear();
        for (let i = 0; i< data.length; i++) {
            let period = this.formService.createWorkAbroadPeriodForm();
            let otherCountries = data[i].otherCountriesList;
            data[i].otherCountriesList = [];
            period.setValue(data[i]);
            let countriesList = period.get('otherCountriesList') as FormArray;
            for(let country of otherCountries){
                let otherCountry = this.formService.createWorkCountryForm();
                otherCountry.setValue(country);
                countriesList.push(otherCountry);
            }
            this.formService.partnerPeriodForms.push(period);
        }
        this.formService.setPartnerWorkedAbroad();
    }

    public setStep6Form(data: FinancialSupportModel[]|null|undefined): void{
        if(data==null){
            return;
        }

        this.formService.financialSupportForms.clear();
        this.formService.setApplicantReceivesSupport(data.length != 0)
        for (let i = 0; i< data.length; i++) {
            let form = this.formService.createFinancialSupportForm();
            form.setValue(data[i]);
            this.formService.financialSupportForms.push(form);
        }
    }

    public setStep7Form(data: FinancialSupportModel[]|null|undefined): void{
        if(data==null){
            return;
        }

        this.formService.partnerFinancialSupportForms.clear();
        this.formService.partnerFinancialSupportForm = this.formService.createFinancialSupportForms();
        for (let i = 0; i< data.length; i++) {
            let form = this.formService.createFinancialSupportForm();
            form.setValue(data[i]);
            this.formService.partnerFinancialSupportForms.push(form);
        }
        this.formService.setPartnerReceivesSupport()

    }

    public setStep8Form(data: PreviousFinancialSupportModel|null|undefined): void{
        if(data==null){
            return;
        }
        this.formService.otherFinancialSupportForm.patchValue(data);
        this.formService.setHasOtherFincancialSourceYesNo();
        this.formService.setHasReceivedAidYesNo();
        this.formService.setHasPartnerReceivedAidYesNo();
    }

    public setStepOverviewForm(applicant: ApplicantModel, partner: PartnerModel|null|undefined, children: ChildModel[]): void{
        this.setStep2Form(applicant, partner);
        this.setStep3Form(children);
    }



    setFormSteps(data: AggregatedApplicationModel, applicationId: string){
        let lastStep = data.lastCompletedStep;
        this.stepNumber = data.lastCompletedStep;
        this.applicationId = applicationId;
        this.stripeService.applicationId = applicationId;
        this.formService.setApplicantPartner(data.application.HasPartner)
        this.formService.applicationForm.setValue(data.application);
        this.increaseStepNumber();
    }
}


