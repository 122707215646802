<div class="gender-field">
    <div *ngIf="fieldName!=''" class="input-label__container">
        <div [class]="GetInputLabelClass(fieldName)">{{fieldName}}<span *ngIf="isRequired" class="input-label__required-star"> *</span></div>
    </div>
    <div class="gender-field__options">
        <button [class]="manClass" (click)="onValueChange('man')">
            <div class="title-small">Man</div>
        </button>
        <button [class]="womanClass" (click)="onValueChange('vrouw')">
            <div class="title-small">Vrouw</div>
        </button>
    </div>
</div>

<app-error *ngIf="showError()" [errors]="errors"/>
