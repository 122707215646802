<div class="status-container">
    <div *ngIf="isPaymentSuccess">
        <div class="status-section">
            <img src="assets/icons/success.svg" alt="" width="70">
            <h1>Bedankt!</h1>
            <div class="thankyou-text"> Eén van onze specialisten controleert uw aanvraag, zorgt ervoor dat deze compleet is en dient deze vervolgens in</div>
        </div>
        <div class="progress-section">
            <img src="assets/icons/progress.svg" alt="" >
            <div class="status-description">
                <div class="status-description-section">
                    <img src="assets/icons/status1.svg" alt="" width="42">
                    <div class="status-description-title">Machtig onze specialist</div>
                    <div>U ontvangt een verzoek binnen 24 uur</div>
                </div>
                <div class="status-description-section">
                    <img src="assets/icons/status2.svg" alt="" width="42">
                    <div class="status-description-title">Specialist vraagt bijslag voor u aan</div>
                    <div>U ontvangt hierover bevestiging</div>
                </div>
                <div class="status-description-section">
                    <img src="assets/icons/status3.svg" alt="" width="42">
                    <div class="status-description-title">U ontvangt kinderbijslag</div>
                    <div>Binnen 4 weken ontvangt u de beoordeling. </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isPaymentSuccess">
        <div class="status-section">
            <img src="assets/icons/failure.svg" alt="" width="70">
            <h1>Oeps!</h1>
            <div class="thankyou-text">Uw betaling is helaas niet gelukt, één van onze specialisten zal contact met u opnemen</div>
        </div>
        <div class="progress-section failure">
            <img src="assets/icons/progress-failure.svg" alt="" width="100%">
            <div class="status-description failure-text">
                <div class="status-description-section">
                    <img src="assets/icons/status1-error.svg" alt="" width="42">
                    <div class="status-description-title">Machtig onze specialist</div>
                    <div>U ontvangt een verzoek binnen 24 uur</div>
                </div>
                <div class="status-description-section">
                    <img src="assets/icons/status2-error.svg" alt="" width="42">
                    <div class="status-description-title">Specialist vraagt bijslag voor u aan</div>
                    <div>U ontvangt hierover bevestiging</div>
                </div>
                <div class="status-description-section">
                    <img src="assets/icons/status3-error.svg" alt="" width="42">
                    <div class="status-description-title">U ontvangt kinderbijslag</div>
                    <div>Binnen 4 weken ontvangt u de beoordeling. </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actionbar">
        <app-requestbutton [light]="isPaymentSuccess==false" buttonText="Terug naar de startpagina" url="/"/>
        <app-requestbutton *ngIf="!isPaymentSuccess" [light]="true" buttonText="Neem Contact op met ons" url="/contact"/>
    </div>

</div>

