import { Component } from '@angular/core';
import { TextFieldComponent } from '../../SharedComponents/fields/text-field/text-field.component';
import { RequestbuttonComponent } from "../../SharedComponents/requestbutton/requestbutton.component";
import { FormControl, FormsModule } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.css',
    imports: [TextFieldComponent, RequestbuttonComponent, FormsModule]
})

export class LoginComponent {
  username:string = ""
  password:string = ""

  constructor(private dataService: DataService, private route: Router, private authenticationService: AuthenticationService){}
  login():boolean{
    this.dataService.login(this.username, this.password).subscribe(
      token =>{
        console.log("Success",)
        console.log(token.token);
        this.route.navigate(["admin"])
        this.authenticationService.login(token.token)
      },
      error=>{
        console.error("Unauthorized");
      }
    )
    return true;
  }
}
