import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { RadioFieldComponent } from '../../../../SharedComponents/fields/radio-field/radio-field.component';
import { DateFieldComponent } from '../../../../SharedComponents/fields/date-field/date-field.component';
import { FormModelService } from '../../../../services/formModel/form-model.service';
import { StepService } from '../../../../services/step/step.service';
import {ApplicantModel, Application, ApplicationModel} from "../../../../models/applicant.model";
import {
    ApplicationDialogueComponent
} from "../../../../SharedComponents/application-dialogue/application-dialogue.component";
import {MatDialog} from "@angular/material/dialog";
import {
    NotificationDialogueComponent
} from "../../../../SharedComponents/notification-dialogue/notification-dialogue.component";

@Component({
  selector: 'app-step1',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatRadioModule,
    RadioFieldComponent,
    DateFieldComponent,
  ],
  templateUrl: './step1.component.html',
  styleUrl: '../../step.component.css'
})

export class Step1Component implements OnInit {

    @Input() data: ApplicationModel | undefined;

    private showDialogue: boolean = false;

    constructor(public stepService: StepService,
                public formService: FormModelService,
                private dialog: MatDialog) {

    }

    ngOnInit(): void {
        this.stepService.setStep1Form(this.data);
    }


    Options1_1 = [
        "Kind net geboren",
        "Ik ben in Nederland komen werken",
        "Mijn kind is geboren buiten Nederland",
        "Mijn kind is bij mij komen wonen",
        "Ik heb een kind geadopteerd",
        "Ik heb een pleegkind opgenomen",
        "Een kind van mijn partner is bij mij komen wonen",
        "Mijn kind woont thuis maar de oorspronkelijke aanvrager niet",
    ]
    fieldName = "Volledige voornamen";

    Options1_3 = [
        "Nee, ik ben alleenstaand",
        "Ja, mijn partner woont op hetzelfde adres",
        "Ja, mijn partner woont op een ander adres"
    ]

    controlValue(controlName: string): string {
        return this.formService.applicationForm.get(controlName)?.value;
    }

    onInputChanged(value: string): void {
        if(!this.showDialogue && value=="Kind net geboren"){
            this.showNotification();
            this.showDialogue = true;
        }
    }

    public showNotification() {
        const dialogRef = this.dialog.open(NotificationDialogueComponent);

        dialogRef.afterClosed().subscribe(_ => {
        })
    }


}
