import { CommonModule } from '@angular/common';
import {
    AfterContentChecked, AfterContentInit,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import { ErrorComponent } from '../../error/error.component';
import { FormModelService } from '../../../services/formModel/form-model.service';
import { TextFieldComponent } from "../text-field/text-field.component";
import {generateUniqueId, GetInputLabelClass} from "../Helpers";

@Component({
    selector: 'app-radio-field',
    standalone: true,
    templateUrl: './radio-field.component.html',
    styleUrls: ['../../step-field/step-field.component.css', './radio-field.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RadioFieldComponent),
        multi: true
    }],
    imports: [CommonModule, ErrorComponent, TextFieldComponent]
})

export class RadioFieldComponent implements ControlValueAccessor, OnInit, AfterContentChecked, AfterContentInit {
    @Input() options: string[] = [];
    @Input() question: string = "";
    @Input() fieldName: string  ="";
    @Input() hasOpenField: boolean = false;
    @Input() errors: string[] = []
    @Input() submitted: boolean = false;
    @Output() inputChange = new EventEmitter<string>;

    public isRequired:boolean = false;
    public value: string = "";
    public random: string = (Math.random() * (1000 - 10000) + 1000).toString();
    public openFieldValue: string = "";
    public divide: [number, number]=[0,0]
    private formControl: NgControl|undefined;
    public uniqueId: string = ""
    constructor( private _injector: Injector, private formService: FormModelService){}

    ngOnInit(): void {
        this.uniqueId = generateUniqueId();
        this.divide = [this.options.length, this.options.length];
        this.formControl = this._injector.get(NgControl);
    }

    ngAfterContentInit() {
        if(this.fieldName!="" && !this.fieldName.includes("?")){
            this.fieldName+=":"
        }
        this.isRequired = this.formControl?.control?.hasValidator(Validators.required) ?? false;
    }

    ngAfterContentChecked(): void {
        this.handleNewValue(this.formControl?.value);
    }
    onChange: any = () => {};

    onTouched: any = () => {};

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    handleOptionClick(val: string){
        this.handleNewValue(val);
        this.openFieldValue="";
    }

    handleNewValue(val: string){
        this.value = val;
        this.onChange(this.value);
        this.onTouched();
        this.inputChange.emit(val)
        if(!this.options.includes(val)){
            this.openFieldValue=val
        }
    }

    setOpenFieldValue(val: string){
        this.handleNewValue(val);
        this.openFieldValue =val
    }
    showError():boolean{
        if(this.submitted && this.formControl && this.formControl?.invalid){
            this.errors = this.formService.getErrorMsg(this.formControl)
            return true;
        }
        return false;
    }

    protected readonly GetInputLabelClass = GetInputLabelClass;
}


