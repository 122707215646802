<div class="disclaimer-page">
    <div class="disclaimer-section">
        <div class="disclaimer-container">
            <h1>Disclaimer</h1>

            <p><strong>Volgens § 5 TMG</strong></p>
            <p>
                Mijn Subsidie B.V.<br>
                Verdunplein 17<br>
                5627 SZ Eindhoven<br>
            </p>

            <section>
                <h2>Inschrijving registreren</h2>
                <p>
                    Inschrijving in het handelsregister (Kamer van Koophandel).<br>
                    Registergericht: Nijkerk<br>
                    BTW-nummer: NL865101358B01<br>
                </p>
            </section>

            <section>
                <h2>Vrijwaring: Aansprakelijkheid voor inhoud</h2>
                <p>
                    De inhoud van onze pagina's is met grote zorg samengesteld. We kunnen echter
                    niet garanderen dat de inhoud juist, volledig of actueel is. Als dienstverlener zijn wij
                    verantwoordelijk voor onze eigen inhoud op deze pagina's. Volgens §§ 8 tot 10 TMG
                    zijn wij als dienstverlener echter niet verplicht om verzonden of opgeslagen
                    informatie van derden te controleren of om omstandigheden te onderzoeken die
                    duiden op illegale activiteiten. Verplichtingen tot verwijdering of blokkering van het
                    gebruik van informatie volgens de algemene wetten blijven onaangetast.
                    Aansprakelijkheid in dit verband is echter pas mogelijk vanaf het tijdstip waarop
                    kennis van een concrete wetsovertreding bekend is.
                </p>
            </section>

            <section>
                <h2>Aansprakelijkheid voor koppelingen</h2>
                <p>
                    Ons aanbod bevat links naar externe websites van derden, op de inhoud waarvan
                    wij geen invloed hebben. Daarom kunnen wij geen aansprakelijkheid aanvaarden
                    voor deze externe inhoud. Voor de inhoud van de gelinkte pagina's is altijd de
                    betreffende aanbieder of exploitant van de pagina's verantwoordelijk. De gelinkte
                    pagina's werden op het moment van linken gecontroleerd op mogelijke
                    wetsovertredingen. Illegale inhoud was op het moment van linken niet herkenbaar.
                    Een permanente controle van de inhoud van de gelinkte pagina's is echter niet
                    redelijk zonder concreet bewijs van een inbreuk. Zodra we ons bewust worden van
                    wetsovertredingen, zullen we dergelijke links onmiddellijk verwijderen.
                </p>
            </section>

            <section>
                <h2>Auteursrechten</h2>
                <p>
                    De inhoud en werken op deze pagina's die door de site-exploitanten zijn gemaakt,
                    vallen onder het auteursrecht. De vermenigvuldiging, bewerking, distributie en elke
                    vorm van exploitatie buiten de grenzen van het auteursrecht vereist de schriftelijke
                    toestemming van de betreffende auteur of maker. Downloads en kopieën van deze
                    site zijn alleen toegestaan voor privé, niet-commercieel gebruik. Voor zover de
                    inhoud van deze site niet door de exploitant is gemaakt, worden de auteursrechten
                    van derden in acht genomen. Met name inhoud van derden is als zodanig
                    gemarkeerd. Mocht u desondanks op de hoogte worden gebracht van een inbreuk
                    op het auteursrecht, dan verzoeken wij u ons hiervan op de hoogte te stellen. Zodra
                    we ons bewust worden van wetsovertredingen, zullen we dergelijke inhoud
                    onmiddellijk verwijderen.
                </p>
            </section>

            <section>
                <h2>Gegevensbescherming</h2>
                <p>
                    Onze website kan meestal worden gebruikt zonder persoonlijke gegevens te
                    verstrekken. Voor zover op onze website persoonsgegevens (bijv. naam, adres of
                    e-mailadressen) worden verzameld, gebeurt dit voor zover mogelijk altijd op
                    vrijwillige basis. Deze gegevens worden zonder uw uitdrukkelijke toestemming niet
                    aan derden doorgegeven. Wij wijzen erop dat de gegevensoverdracht via internet
                    (bijv. bij communicatie via e-mail) veiligheidslacunes kan vertonen. Een volledige
                    bescherming van de gegevens tegen toegang door derden is niet mogelijk. Het
                    gebruik van contactgegevens die als onderdeel van de afdrukverplichting door
                    derden zijn gepubliceerd om ongevraagd reclame- en informatiemateriaal te
                    verzenden, is hierbij uitdrukkelijk verboden. De site-exploitanten behouden zich
                    uitdrukkelijk het recht voor om gerechtelijke stappen te ondernemen in het geval van
                    ongevraagde reclame, zoals spam-e-mails.
                </p>
            </section>

            <section>
                <h2>Google Analytics</h2>
                <p>
                    Deze website maakt gebruik van Google Analytics, een webanalyse-service die
                    wordt aangeboden door Google Inc. ("Google"). Google Analytics maakt gebruik van
                    zogenaamde "cookies", tekstbestanden die op uw computer worden opgeslagen en
                    een analyse van uw gebruik van de website mogelijk maken. De door de cookie
                    gegenereerde informatie over uw gebruik van deze website (inclusief uw IP-adres)
                    wordt overgebracht naar een server van Google in de VS en daar opgeslagen.
                    Google zal deze informatie gebruiken om bij te houden hoe u de website gebruikt,
                    rapporten over de website-activiteit op te stellen voor website-exploitanten en andere
                    diensten aan te bieden met betrekking tot website-activiteit en internetgebruik.
                    Google kan deze informatie ook doorgeven aan derden, als dit wettelijk verplicht is of
                    als derden deze gegevens namens Google verwerken. Google zal in geen geval uw
                    IP-adres combineren met andere gegevens van Google. U kunt de installatie van
                    cookies voorkomen door uw browsersoftware dienovereenkomstig in te stellen; wij
                    wijzen u er echter op dat u in dat geval mogelijk niet alle functies van deze website
                    volledig kunt gebruiken. Door gebruik te maken van deze website geeft u
                    toestemming voor het verwerken van uw gegevens door Google op de wijze en voor
                    de doeleinden zoals hiervoor omschreven.
                </p>
            </section>

            <section>
                <h2>Google AdSense</h2>
                <p>
                    Deze website maakt gebruik van Google Adsense, een webadvertentieservice van
                    Google Inc., VS ("Google"). Google Adsense maakt gebruik van zogenaamde
                    "cookies" (tekstbestandjes), die op uw computer worden opgeslagen en een analyse
                    van uw gebruik van de website mogelijk maken. Google Adsense gebruikt ook
                    zogenaamde "Web Beacons" (kleine onzichtbare afbeeldingen) om informatie te
                    verzamelen. Door gebruik te maken van de web beacon kunnen eenvoudige
                    handelingen zoals bezoekersverkeer op de website worden geregistreerd en
                    verzameld. De door de cookie en/of web beacon gegenereerde informatie over uw
                    gebruik van deze website (inclusief uw IP-adres) wordt overgebracht naar een server
                    van Google in de VS en daar opgeslagen. Google zal deze informatie gebruiken om
                    uw gebruik van de website met betrekking tot de advertenties te evalueren, om
                    rapporten over de website-activiteit en advertenties voor de website-exploitant
                    samen te stellen en om andere diensten aan te bieden met betrekking tot
                    website-activiteit en internetgebruik. Google kan deze informatie ook aan derden
                    verschaffen indien Google hiertoe wettelijk wordt verplicht of voor zover derden deze
                    gegevens in opdracht van Google verwerken. Google zal in geen geval uw IP-adres
                    combineren met andere gegevens van Google. U kunt de opslag van cookies op uw
                    harde schijf en de weergave van webbakens voorkomen, door in uw
                    browserinstellingen "geen cookies accepteren" te selecteren (in MS Internet Explorer
                    onder "Extra's Internetopties instellingen gegevensbescherming"; in Firefox onder
                    "Extra instellingen gegevensbescherming cookies"); wij wijzen u er echter op dat u in
                    dat geval mogelijk niet alle functies van deze website volledig kunt gebruiken. Door
                    gebruik te maken van deze website geeft u toestemming voor het verwerken van uw
                    gegevens door Google op de wijze en voor de doeleinden zoals hiervoor
                    omschreven.
                </p>
            </section>
        </div>
    </div>
</div>
