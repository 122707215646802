<div class="step-container">
    <div class="step-content" >
        <app-step-loading *ngIf="stepDataLoading" />
        <div *ngIf="stepNumber()<9" class="step-title">
                <div class="title-medium step-title">Kinderbijslag aanvragen</div>
                <div class="step-subheader">{{subheaderText}}</div>
        </div>
        <div *ngIf="stepNumber()==9" class="step-title">
            <div class="title-medium">Overzicht van uw kinderbijslag</div>
            <div>Zorg dat u alle aangeleverde informatie goed controleert voordat u de aanvraag indiend.</div>
        </div>
        <div class="step-progress" *ngIf="isStep()">
            <mat-progress-bar mode="determinate" [value]="stepNumber()*(100/NumSteps)"></mat-progress-bar>
        </div>
        <app-step1 *ngIf="stepNumber()==1" [data]="this.aggregatedApplication?.application"/>
        <app-step2 *ngIf="stepNumber()==2" [dataApplicant]="this.aggregatedApplication?.applicant" [dataPartner]="this.aggregatedApplication?.partner"/>
        <app-step3 *ngIf="stepNumber()==3" [data]="this.aggregatedApplication?.children"/>
        <app-step4 *ngIf="stepNumber()==4" [data]="this.aggregatedApplication?.applicantWorkAbroadPeriods"/>
        <app-step5 *ngIf="stepNumber()==5" [data]="this.aggregatedApplication?.partnerWorkAbroadPeriods"/>
        <app-step6 *ngIf="stepNumber()==6" [data]="this.aggregatedApplication?.applicantFinancialSupport"/>
        <app-step7 *ngIf="stepNumber()==7" [data]="this.aggregatedApplication?.partnerFinancialSupport"/>
        <app-step8 *ngIf="stepNumber()==8" [data]="this.aggregatedApplication?.previousFinancialSupport"/>
        <app-step-overview *ngIf="stepNumber()==9"
                           [dataApplicant]="this.aggregatedApplication?.applicant"
                           [dataPartner]="this.aggregatedApplication?.partner"
                           [dataChildren]="this.aggregatedApplication?.children"/>
        <app-step-payment *ngIf="stepNumber()==9||stepNumber()==10" [showStep]="stepNumber()==10"/>
        <div class="step-footer">
            <app-requestbutton *ngIf="stepNumber()>1 && stepNumber()<=stepService.lastStep" buttonText="Vorige" (buttonClick)="stepService.previousStep()"/>
            <app-requestbutton
                [buttonText]="stepNumber()!=10? 'Volgende': 'Afronden en Betalen'"
                (buttonClick)="stepService.nextStep()"
                [isLoading]="stepService.isNextButtonLoading"/>
        </div>
    </div>
</div>
