import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RequestbuttonComponent } from "../../SharedComponents/requestbutton/requestbutton.component";
import { RequestSectionComponent } from '../../SharedComponents/request-section/request-section.component';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-faq',
    standalone: true,
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css', './faq.mobile.component.css'],
    imports: [MatExpansionModule, RequestbuttonComponent, RequestSectionComponent, CommonModule]
})
export class FAQComponent {
    questions= [
        "Wanneer kan ik kinderbijslag aanvragen?",
        "Hoe kan ik kinderbijslag aanvragen?",
        "Hoe machtig ik Mijn Bijslag via DigiD voor het doen van mijn aanvraag?",
        "Ik krijg een tweede kindje, moet ik opnieuw kinderbijslag aanvragen?",
        "Hoeveel kinderbijslag krijg ik?",
        "Wat verandert er met de kinderbijslag als mijn kind 16 wordt?",
        "Wat moet ik doen als mijn persoonlijke situatie verandert?",
        "Welke ouder krijgt de kinderbijslag na een scheiding?",
        "Vanaf wanneer ontvangt u kinderbijslag?",
    ]

    answers=[
        "Heeft u de geboorte van uw kind bij uw gemeente gemeld? Dan krijgt u binnen 4 weken een bericht dat u kinderbijslag kunt aanvragen.",
        "Om zeker te zijn van een correcte afhandeling is het mogelijk om via Mijn Bijslag uw aanvraag voor kinderbijslag in te dienen. Een van onze bijslag-experts zal uw aanvraag bekijken, zorgen dat deze compleet is en zal deze vervolgens correct indienen. Het is ook mogelijk kinderbijslag direct bij de SBV aan te vragen. Mijn Bijslag rekent kosten voor onze dienstverlening.",
        "<p>Voordat wij aan het werk kunnen gaan, moet u ons een officiële machtiging toekennen via DigiD. Door het afgeven van deze machtiging wordt een van onze bijslag specialisten gemachtigd om de aanvraag namens u in te dienen.</p> <p>Nadat u de aanvraag bij ons heeft ingediend zal de specialist aan wie het dossier is toegewezen u direct een machtigingsverzoek sturen. Dit gebeurt meestal binnen 24 uur na het doen van uw aanvraag.</p>",
        "Nee, dit hoeft niet meer. Indien uw gezinssituatie verder niet veranderd, hoeft u niets aan te geven. Meld de geboorte van uw kind bij de gemeente en u krijgt vervolgens vanzelf de kinderbijslag uitbetaald. ",
        "De hoogte van de kinderbijslag is afhankelijk van de leeftijd van het kind. In 2024 krijgt u de volgende bedragen voor uw kinderen:<ul>" +
        "<li>0 t/m 5 jaar een bedrag van € 279,49</li>" +
        "<li>6 t/m 11 jaar een bedrag van € 339,38</li>" +
        "<li>12 t/m 17 jaar een bedrag van € 399,27</li>" +
        "</ul>Kinderbijslag wordt elk kwartaal uitbetaald. De betalingen vinden plaats in januari, april, juli en oktober.",
        "Vanaf dat het kind 16 is maakt u alleen nog aanspraak op kinderbijslag als uw kind nog geen startkwalificatie heeft. Een startkwalificatie is een diploma van de havo, het vwo of het mbo niveau 2 of hoger. Een diploma van een beroepsbegeleidende leerweg (BBL) en beroepsopleidende leergang (BOL) niveau 2 of hoger is ook een startkwalificatie.",
        "Als uw persoonlijke situatie verandert, bijvoorbeeld bij een verhuizing, scheiding of als uw kind uit huis gaat, moet u dit doorgeven aan de SVB. Deze veranderingen kunnen invloed hebben op uw recht op kinderbijslag.",
        "De ouder bij wie het kind woont krijgt de kinderbijslag. Kreeg u voor de scheiding de kinderbijslag? En woont uw kind na de scheiding bij u? Dan verandert er niks. Gaat het kind bij de andere ouder wonen? Dat wil zeggen 4 nachten per week of meer. Geef dat dan door.",
        "Binnen 4 weken na het indienen van de aanvraag krijgt u per mail uitspraak over uw aanvraag. De kinderbijslag gaat in het eerstvolgende kwartaal nadat uw kind is geboren of bij u is ingetrokken. Voorbeeld: Uw kind is geboren in april, dan gaat de kinderbijslag in vanaf het derde kwartaal die begint op 1 juli. U zult dan in oktober de eerste betaling ontvangen. \n",
    ]
}
