import {Component, Input} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-step-loading',
  standalone: true,
    imports: [
        MatProgressSpinner
    ],
  templateUrl: './step-loading.component.html',
  styleUrl: './step-loading.component.css'
})
export class StepLoadingComponent {
    @Input() public loadingText: string = "";
}
