import { Component } from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { RequestbuttonComponent } from '../../SharedComponents/requestbutton/requestbutton.component';
import { RequestSectionComponent } from '../../SharedComponents/request-section/request-section.component';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-home',
  standalone: true,
    imports: [MatExpansionModule, RequestbuttonComponent, RequestSectionComponent, NgForOf],
  templateUrl: './home.component.html',
  styleUrls: ['./home.desktop.component.css', './home.mobile.component.css']
})

export class HomeComponent {
  public panelState: boolean[]=[false, false, false];
  public readonly iconsPath = "../../../assets/icons/"
  public readonly imagesPath = "../../../assets/images/";

    questions= [
        "Hoe kan ik kinderbijslag aanvragen?",
        "Hoe machtig ik Mijn Bijslag via DigiD voor het doen van mijn aanvraag?",
        "Ik krijg een tweede kindje, moet ik opnieuw kinderbijslag aanvragen?",
        "Vanaf wanneer ontvangt u kinderbijslag?",
    ]

    answers=[
        "Om zeker te zijn van een correcte afhandeling is het mogelijk om via Mijn Bijslag uw aanvraag voor kinderbijslag in te dienen. Een van onze bijslag-experts zal uw aanvraag bekijken, zorgen dat deze compleet is en zal deze vervolgens correct indienen. Het is ook mogelijk kinderbijslag direct bij de SBV aan te vragen. Mijn Bijslag rekent kosten voor hun dienstverlening.",
        "<p>Voordat wij aan het werk kunnen gaan, moet u ons een officiële machtiging toekennen via DigiD. Door het afgeven van deze machtiging wordt een van onze bijslag specialisten gemachtigd om de aanvraag namens u in te dienen.</p> <p>Nadat u de aanvraag bij ons heeft ingediend zal de specialist aan wie het dossier is toegewezen u direct een machtigingsverzoek sturen. Dit gebeurt meestal binnen 24 uur na het doen van uw aanvraag.</p>",
        "Nee, dit hoeft niet meer. Indien uw gezinssituatie verder niet veranderd, hoeft u niets aan te geven. Meld de geboorte van uw kind bij de gemeente en u krijgt vervolgens vanzelf de kinderbijslag uitbetaald. ",
        "Binnen 4 weken na het indienen van de aanvraag krijgt u per mail uitspraak over uw aanvraag. De kinderbijslag gaat in het eerstvolgende kwartaal nadat uw kind is geboren of bij u is ingetrokken. Voorbeeld: Uw kind is geboren in april, dan gaat de kinderbijslag in vanaf het derde kwartaal die begint op 1 juli. U zult dan in oktober de eerste betaling ontvangen. \n",
    ]


}

