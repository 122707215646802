import {AfterContentChecked, AfterContentInit, Component, HostListener, OnInit} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { RequestbuttonComponent } from '../../SharedComponents/requestbutton/requestbutton.component';
import { Step1Component } from "./Steps/step1/step1.component";
import { Step2Component } from './Steps/step2/step2.component';
import { Step3Component } from './Steps/step3/step3.component';
import { Step4Component } from './Steps/step4/step4.component';
import { Step5Component } from './Steps/step5/step5.component';
import { Step6Component } from './Steps/step6/step6.component';
import { CommonModule } from '@angular/common';
import { StepService } from '../../services/step/step.service';
import { StepOverviewComponent } from "./Steps/step-overview/step-overview.component";
import { StepPaymentComponent } from "./Steps/step-payment/step-payment.component";
import { FormModelService } from '../../services/formModel/form-model.service';
import { Step7Component } from "./Steps/step7/step7.component";
import { Step8Component } from "./Steps/step8/step8.component";
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from "../../services/data/data.service";
import {AggregatedApplicationModel} from "../../models/applicant.model";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import {StepLoadingComponent} from "./Steps/step-loading/step-loading.component";

@Component({
    selector: 'app-step',
    standalone: true,
    templateUrl: './step.component.html',
    styleUrl: './step.component.css',
    imports: [
        MatProgressBarModule,
        RequestbuttonComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        Step5Component,
        Step6Component,
        CommonModule,
        StepOverviewComponent,
        StepPaymentComponent,
        Step7Component,
        Step8Component,
        StepLoadingComponent
    ]
})
export class StepComponent implements OnInit {

    stepDataLoading: boolean = false;
    public aggregatedApplication: AggregatedApplicationModel|undefined;
    public subheaderText: string = this.stepNumber()==3?
        "Voer hier de gegevens in van de kinder waarvoor u kinderbijslag wilt aanvragen":
        "Heeft u onduidelijkheden bij het aanvragen van kinderbijslag? Via Mijn Bijslag kunt u erop vertrouwen dat u zo spoedig mogelijk het juiste bedrag aan bijslag ontvangt. Onze experts zorgen voor een nauwkeurige afhandeling van uw aanvraag. Na het indienen van uw aanvraag zal een van onze experts contact met u opnemen om een machtiging voor het aanvragen van de bijslag te verkrijgen. Onze experts zullen er vervolgens voor zorgen dat uw aanvraag namens u correct en volledig wordt ingediend."

    constructor(
        public stepService: StepService,
        public formService: FormModelService,
        public route: ActivatedRoute,
        public router: Router,
        private dataService: DataService){}

    async ngOnInit(): Promise<void> {
        console.log("Url:", this.router.url)
        const queryParams = this.route.snapshot.queryParams;
        const applicationId = queryParams['applicationId'];
        if(applicationId == undefined){
            return;
        }

        console.log("applicationId:", applicationId); // Outputs the value of a specific query parameter
        this.stepDataLoading = true;
        this.dataService.getAggregatedApplication(applicationId).subscribe(
            async (data) => {
                this.stepService.setFormSteps(data as AggregatedApplicationModel, applicationId);
                this.aggregatedApplication = data as AggregatedApplicationModel;
                this.stepDataLoading = false;
            });
    }

  public isStep(){
    return this.stepService.stepNumber<=this.stepService.lastStep;
  }

  public get NumSteps(): number{
    return this.stepService.lastStep;
  }

  public stepNumber(): number{
    return this.stepService.stepNumber;
  }
}
