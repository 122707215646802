<div>
    <div class="faq-title-section">
        <div style="color: rgb(102, 102,102);">FAQ</div>
        <div class="title-medium">Neem direct contact met ons op</div>
    </div>
    <div class="faq-section">
        <div class="faq-qa-container">
            <div class="faq-qa">
                <mat-expansion-panel *ngFor="let question of questions; index as i" class="expansion-panel" >
                    <mat-expansion-panel-header>
                        <div class="panel-header">
                            <mat-panel-title> <div [innerHtml]="question"></div> </mat-panel-title>
                        </div>
                    </mat-expansion-panel-header>
                    <div [innerHtml]="answers[i]"></div>
                </mat-expansion-panel>
            </div>
            <div class="faq-contact">
                <img src="../../../assets/icons/message.svg" alt=""/>
                <div class="faq-contact__text">
                    <div class="title-small">Zit uw vraag er niet tussen? </div>
                    <p>Neem dan direct contact met ons op en een van onze specialisten probeert u zo snel mogelijk te helpen.</p>
                </div>
                <app-requestbutton style="font-size: 0.875rem;" url="stap"></app-requestbutton>
            </div>
        </div>
    </div>
    <app-request-section></app-request-section>
</div>
