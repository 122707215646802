<div class="contact-page">
    <div class="contact-section">
        <div class="contact-title">
            <div>Binnen 24 uur reactie</div>
            <div class="title-medium">Neem direct contact met ons op</div>
        </div>
        <div class="contact-section-content">
            <form class="content-section" [formGroup]="formService.contactForm">
                <div class="field-group__row" style="width: 100%">
                    <app-text-field fieldName="Naam" formControlName="name" style="flex: 1"/>
                    <app-text-field fieldName="Telefoon" formControlName="phonenumber" style="flex: 1"/>
                </div>
                <div class="field-group__row" style="width: 100%">
                    <app-text-field fieldName="Email" formControlName="email"/>
                    <app-text-field fieldName="Email bevestiging" formControlName="emailConfirmation"/>
                </div>
                <app-text-area-field fieldName="Bericht" formControlName="message" [rows]="3"/>

                @if(formService.contactForm.hasError('emailMismatch') && submitted){
                    <mat-error>E-mailadressen komen niet overeen</mat-error>
                }
                <div class="action-bar">
                    <app-requestbutton [buttonText]="'Sturen'" (buttonClick)="sendMessage()" [url]="'contact'"/>

                </div>
            </form>
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
            <div class="content-section">
                <div class="contact-details email">
                    <img class="icon" src="../../../assets/icons/mail.svg" />
                    <div >
                        <div class="title-small"> Email</div>
                        <a class="footer-contactus__email" href="mailto:info@kinderbijslag.nl">info&#64;kinderbijslag.nl</a>
                    </div>

                </div>
                <div class="contact-details address">
                    <img class="icon"src="../../../assets/icons/location.svg"/>
                    <div>
                        <div class="title-small"> Adres</div>
                        <div>Verdunplein 17, 5627 SZ Eindhoven, Nederland</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
