import { Component } from '@angular/core';
import { HeaderComponent } from '../../core/header/header.component';
import { FooterComponent } from '../../core/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { MenuService } from '../../services/menu/menu.service';
import { CommonModule } from '@angular/common';
import { MenuItem } from '../../SharedComponents/types';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, CommonModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent {

  constructor(public menuService: MenuService){

  }

  pages: MenuItem[] = [
    {title: "Home", url: ""},
    {title: "Meer weten", url: "meer-weten"},
    {title: "Contact", url: "contact"},
    {title: "Meestgestelde vragen", url: "faq"},
  ]

  public menuButtonHandler() {
    console.log("CLick");
    this.menuService.toggleMenu()
  }

}
