import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AggregatedApplicationModel,
    Applicant,
    ApplicantModel,
    Application,
    ApplicationModel,
    ChildModel, FinancialSupportModel,
    PartnerModel, PreviousFinancialSupportModel, WorkAbroadPeriodModel
} from '../../models/applicant.model';
import { PaymentReceipt } from '../../models/payment.model';
import { environment } from '../../../environments/environment'
import {ContactModel} from "../../models/contact.model";

@Injectable({
  providedIn: 'root',

})

export class DataService {

    private headers: HttpHeaders;
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({
            "Content-type": "application/json"
        });
    }

    public getApplicationById(applicationId: string): Observable<Application> {
        return this.http.get<Application>(this.apiUrl + "application/" + applicationId);
    }

    /*public postApplication(data: Application): Observable<void> {
        // TODO: Weghalen: moet alleen data als type Application hebben
        return this.http.post<any>(
            this.apiUrl + "Applications/add",
            data,
            {headers: this.headers}
        );
    }*/

    public login(username: string, password: string): Observable<any> {
        return this.http.post<any>(
            this.apiUrl + "Auth/login",
            {username, password},
        );
    }

    public updateState(intent: string) {
        console.log("intent:", intent)
        return this.http.get<boolean>(
            this.apiUrl + `Applications/updateApplicationState?intent=${intent}`
        )
    }

    public updateStateById(applicationId: string, status: string) {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('state', status);

        return this.http.put(
            this.apiUrl + "applications/updatestate?", {}, {params}
        )
    }

    public getApplications(): Observable<Application[]> {
        return this.http.get<Application[]>(this.apiUrl + "applications");
    }

    public updateApplication(application: Application): Observable<any> {
        return this.http.put<Application>(
            this.apiUrl + "applications/edit",
            application,
            {headers: this.headers}
        )
    }

    public sendContactMessage(contact: ContactModel): Observable<any> {
        console.log("contact:", contact)
        return this.http.post<any>(
            this.apiUrl + "Bijslag/contact",
            contact
        );
    }

    public createInitialApplication(application: ApplicationModel, stepNumber:number, applicationId: string): Observable<string> {
        return this.http.post<string>(
            this.apiUrl + `Applications/create?lastCompletedStep=${stepNumber}&applicationId=${applicationId}`,
            application,
            {headers: this.headers}
        )
    }

    public updateApplicant(applicant: ApplicantModel, applicationId: string, stepNumber:number): Observable<any> {
        return this.http.put<any>(
            this.apiUrl + `applications/update/applicant?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            applicant,
            {headers: this.headers}
        )
    }

    public updatePartner(partner: PartnerModel, applicationId: string, stepNumber:number): Observable<any> {
        if (partner == null) {
            console.error("partner is null")
        } else {
            console.log("partner:", partner)
        }

        return this.http.put<any>(
            this.apiUrl + `applications/update/partner?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            partner,
            {headers: this.headers}
        )
    }

    public updateChildren(children: ChildModel[], applicationId: string, stepNumber:number): Observable<any> {
        console.log("updateChildren, ",children);
        return this.http.put<any>(
            this.apiUrl + `applications/update/children?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            children,
            {headers: this.headers}
        )
    }

    public updateApplicantWorkAbroadPeriods(periods: WorkAbroadPeriodModel[], applicationId: string, stepNumber:number): Observable<any> {
        return this.http.put<any>(
            this.apiUrl + `applications/update/applicantWorkPeriods?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            periods,
            {headers: this.headers}
        )
    }
    public updatePartnerWorkAbroadPeriods(periods: WorkAbroadPeriodModel[], applicationId: string, stepNumber:number): Observable<any> {
        console.log(periods)
        return this.http.put<any>(
            this.apiUrl + `applications/update/partnerWorkPeriods?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            periods,
            {headers: this.headers}
        )
    }

    public updateFinancialSupport(support: FinancialSupportModel[], applicationId: string, stepNumber:number): Observable<any> {
        console.log(support)
        return this.http.put<any>(
            this.apiUrl + `applications/update/applicantFinancialSupport?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            support,
            {headers: this.headers}
        )
    }

    public updatePartnerFinancialSupport(support: FinancialSupportModel[], applicationId: string, stepNumber:number): Observable<any> {
        console.log(support)
        return this.http.put<any>(
            this.apiUrl + `applications/update/partnerFinancialSupport?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            support,
            {headers: this.headers}
        )
    }

    public updatePreviousSupport(support: PreviousFinancialSupportModel, applicationId: string, stepNumber:number): Observable<PaymentReceipt> {
        console.log(support)
        return this.http.put<PaymentReceipt>(
            this.apiUrl + `applications/update/previousFinancialSupport?applicationId=${applicationId}&lastCompletedStep=${stepNumber}`,
            support,
            {headers: this.headers}
        )
    }

    public getAggregatedApplication(applicationId: string): Observable<AggregatedApplicationModel> {
        return this.http.get<AggregatedApplicationModel>(this.apiUrl + `applications/aggregated?applicationId=${applicationId}`);
    }

    public createPayment(applicationId: string): Observable<PaymentReceipt> {
        return this.http.get<PaymentReceipt>(
            this.apiUrl + `applications/createPayment?applicationId=${applicationId}`)
    }
}
