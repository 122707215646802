import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public authenticated: boolean = false;


  constructor() { }

  public login(token:string){
    this.authenticated = true;
    sessionStorage.setItem("token",token);
  }

  public logout(){
    this.authenticated = false;
    sessionStorage.removeItem("token");
  }

  public isLoggedIn():boolean{
    return sessionStorage.getItem("token")!=null;
  }
}
