<div class="text-field" style="width: 100%; flex: 1">
    <div class="input-label__container">
        <div class="input-label">Geef hier een duidelijk beschrijving van uw vraag: <span class="input-label__required-star"> *</span></div>
    </div>
    <mat-form-field class="mat-form-field area" appearance="outline">
                        <textarea matInput
                                  [rows]=rows>
                        </textarea>
    </mat-form-field>
</div>
