<div class="request-section">
    <div class="home-section__content request-section__content">
        <div class="request-section__request">
            <div>Help bij het aanvragen van uw kinderbijslag?</div>
            <h3 class="title-medium">Vraag direct uw kinderbijslag aan</h3>
            <app-requestbutton [light]="true" url="stap"/>
        </div>
        <div class="request-section__image-section">
            <img class="request-section__image" [src]="imagesPath+'home11.png'" alt=""/>
        </div>
    </div>

</div>
