<form [formGroup]="formService.partnerFinancialSupportForm!">
    <div class="step-header">
        <div class="field-group__container stepheader">
            <div class="section-header">Huidige uitkeringen van uw partner</div>
            <div class="field-group">
                <app-dualoption-field fieldName="Ontvangt uw partner momenteel een uitkering?"
                                      formControlName="receivesSupport"
                                      [duoOption]="['Ja', 'Nee']"
                                      (inputChange)="setPartnerReceivesSupport($event)"
                                      [submitted]="stepService.stepSubmitted[6]"/>
            </div>
        </div>
    </div>
    <div formArrayName="financialSupportForms" *ngIf="receivesSupport">
        <div *ngFor="let financialSupportForm of formService.partnerFinancialSupportForms.controls; index as i"  [ngClass]="'step '+String(i+1)" [formGroupName]="i">
            <div class="field-group__container">
                <div class="field-group__section__header" >
                    <div> Uitkering</div>
                    <button *ngIf="i>0" class="delete-button" (click)="formService.removePartnerFinancialSupportForm(i)">Verwijderen</button>
                </div>
                <div class="field-group">
                    <app-radio-field
                        [options]="options"
                        fieldName="Wat voor soort uitkering?"
                        [hasOpenField]="true"
                        formControlName="financialSupportType"
                        [submitted]="stepService.stepSubmitted[6]"/>
                    <app-date-field
                        fieldName="Sinds wanneer ontvangt uw partner deze uitkering?"
                        formControlName="startDate"
                        style="width: 50%"
                        [submitted]="stepService.stepSubmitted[6]"/>
                    <app-select-field
                        fieldName="Uit welk land wordt deze uitkering ontvangen?"
                        formControlName="country"
                        style="width: 50%"
                        [submitted]="stepService.stepSubmitted[6]"/>
                </div>
            </div>

        </div>
        <div class="add-section" (click)="formService.addPartnerFinancialSupportForm()">
            <img src="../../../../../assets/icons/add.svg" alt="add"/>
            <div>Uitkering toevoegen</div>
        </div>
    </div>
</form>

