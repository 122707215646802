<div class="terms-page">
    <div class="terms-section">
        <div class="terms-container">
            <h1>Algemene Voorwaarden - Mijn Bijslag</h1>
            <section>
                <h2>Artikel 1 - Definities</h2>
                <ol>
                    <li><strong>Aanvullende overeenkomst:</strong> een overeenkomst waarbij de consument producten, digitale inhoud en/of diensten verwerft in verband met een overeenkomst op afstand en deze zaken, digitale inhoud en/of diensten door de ondernemer worden geleverd of door een derde partij op basis van een afspraak tussen die derde en de ondernemer.</li>
                    <li><strong>Bedenktijd:</strong> de termijn waarbinnen de consument gebruik kan maken van zijn herroepingsrecht;</li>
                    <li><strong>Consument:</strong> de natuurlijke persoon die niet handelt voor doeleinden die verband houden met zijn handels-, ambachts- of beroepsactiviteit;</li>
                    <li><strong>Digitale inhoud:</strong> gegevens die in digitale vorm geproduceerd en geleverd worden;</li>
                    <li><strong>Duurzame gegevensdrager:</strong> elk hulpmiddel – waaronder ook begrepen e-mail – dat de consument of ondernemer in staat stelt om informatie die aan hem persoonlijk is gericht, op te slaan op een manier die toekomstige raadpleging of gebruik gedurende een periode die is afgestemd op het doel waarvoor de informatie is bestemd, en die ongewijzigde reproductie van de opgeslagen informatie mogelijk maakt;</li>
                    <li><strong>Herroepingsrecht:</strong> de mogelijkheid van de consument om binnen de bedenktijd af te zien van de overeenkomst op afstand;</li>
                    <li><strong>Ondernemer:</strong> de natuurlijke of rechtspersoon die lid is van de Nederlandse Thuiswinkel Organisatie en producten, (toegang tot) digitale inhoud en/of diensten op afstand aan consumenten aanbiedt;</li>
                    <li><strong>Overeenkomst op afstand:</strong> een overeenkomst die tussen de ondernemer en de consument wordt gesloten in het kader van een georganiseerd systeem voor verkoop op afstand van producten, digitale inhoud en/of diensten, waarbij tot en met het sluiten van de overeenkomst uitsluitend of mede gebruik gemaakt wordt van één of meer technieken voor communicatie op afstand;</li>
                    <li><strong>Techniek voor communicatie op afstand:</strong> middel dat kan worden gebruikt voor het sluiten van een overeenkomst, zonder dat consument en ondernemer gelijktijdig in dezelfde ruimte hoeven te zijn samengekomen;</li>
                </ol>
            </section>

            <section>
                <h2>Artikel 2 - Identiteit van de ondernemer</h2>
                <p>
                    Mijn Subsidie BV<br>
                    Verdunplein 17<br>
                    5627 SZ Eindhoven<br>
                    Nederland<br>
                    <a href="https://www.mijn-bijslag.nl">www.mijn-bijslag.nl</a><br>
                    +31850022358<br>
                    <a href="mailto:info@mijn-bijslag.nl">info&#64;mijn-bijslag.nl</a>
                </p>
            </section>

            <section>
                <h2>Artikel 3 - Het aanbod </h2>
                <ol>
                    <li>Het aanbod bevat een volledige en nauwkeurige omschrijving van de aangeboden producten, digitale inhoud en/of diensten weergeven op de website. De beschrijving is voldoende gedetailleerd om een goede beoordeling van het aanbod door de consument mogelijk te maken.</li>
                    <li>Elk aanbod bevat zodanige informatie, dat voor de consument duidelijk is wat de rechten en verplichtingen zijn, die aan de aanvaarding van het aanbod zijn verbonden.</li>
                </ol>
            </section>

            <section>
                <h2>Artikel 4 - Toepasselijkheid</h2>
                <ol>
                    <li>Deze algemene voorwaarden zijn van toepassing op elk aanbod van de ondernemer en op elke tot stand gekomen overeenkomst op afstand tussen ondernemer en consument.</li>
                    <li>Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden aan de consument beschikbaar gesteld. Indien de overeenkomst op afstand elektronisch wordt gesloten, kan de tekst van deze algemene voorwaarden langs elektronische weg aan de consument ter beschikking worden gesteld op zodanige wijze dat deze door de consument op een eenvoudige manier kan worden opgeslagen op een duurzame gegevensdrager. Indien dit redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op afstand wordt gesloten, worden aangegeven waar van de algemene voorwaarden langs elektronische weg kan worden kennisgenomen en dat zij op verzoek van de consument langs elektronische weg of op andere wijze kosteloos zullen worden toegezonden.</li>
                    <li>Indien één of meerdere bepalingen in deze algemene voorwaarden op enig moment geheel of gedeeltelijk nietig zijn of vernietigd worden, dan blijft de overeenkomst en deze voorwaarden voor het overige in stand en zal de betreffende bepaling in onderling overleg onverwijld vervangen worden door een bepaling dat de strekking van het oorspronkelijke zoveel mogelijk benaderd.</li>
                </ol>
            </section>

            <section>
                <h2>Artikel 5 - Toepasselijkheid</h2>
                <ol>
                    <li>De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4, tot stand op het moment van aanvaarding door de consument van het aanbod en het voldoen aan de daarbij gestelde voorwaarden.</li>
                    <li>Indien de consument het aanbod langs elektronische weg heeft aanvaard, bevestigt de ondernemer onverwijld langs elektronische weg de ontvangst van de aanvaarding van het aanbod. Zolang de ontvangst van deze aanvaarding niet door de ondernemer is bevestigd, kan de consument de overeenkomst ontbinden.</li>
                    <li>Indien de overeenkomst elektronisch tot stand komt, treft de ondernemer passende technische en organisatorische maatregelen ter beveiliging van de elektronische overdracht van data en zorgt hij voor een veilige web omgeving. Indien de consument elektronisch kan betalen, zal de ondernemer daartoe passende veiligheidsmaatregelen in acht nemen.</li>
                    <li>Na het tot stand komen van de overeenkomst begint de ondernemer direct met het vervullen van zijn overeengekomen diensten. Na het sluiten van de overeenkomst omvatten de verrichte werkzaamheden van de ondernemer, waaronder maar niet beperkt tot het analyseren van de juistheid van de gegevens van de consument, het kloppend maken van de gegevens van de consument en het bevestigen van de aanvraag aan de consument.</li>
                    <li>De ondernemer kan zich binnen wettelijke kaders – op de hoogte stellen of de consument aan zijn betalingsverplichtingen kan voldoen, alsmede van al die feiten en factoren die van belang zijn voor een verantwoording van de overeenkomst op afstand. Indien de ondernemer op grond van dit onderzoek goede gronden heeft om de overeenkomst niet aan te gaan, is hij gerechtigd gemotiveerd een bestelling of aanvraag te weigeren of aan de uitvoering bijzondere voorwaarden te verbinden.</li>
                    <li>De ondernemer zal uiterlijk bij levering van het product, de dienst of digitale inhoud aan de consument de volgende informatie, schriftelijk of op zodanige wijze dat deze door de consument op een toegankelijke manier kan worden opgeslagen op een duurzame gegevensdrager, meesturen:<ol class="sublist">    <li>        het bezoekadres van de vestiging van de ondernemer waar de consument met klachten terecht kan;    </li>    <li>        de voorwaarden waaronder en de wijze waarop de consument van het herroepingsrecht gebruik kan maken, dan wel een duidelijke melding inzake het uitgesloten zijn van het herroepingsrecht;    </li>    <li>        de informatie over garanties en bestaande service na aankoop;    </li>    <li>        de prijs met inbegrip van alle belastingen van het product, dienst of digitale inhoud; voor zover van toepassing de kosten van aflevering; en de wijze van betaling,    </li></ol></li>
                    <li>In geval van een duurtransactie is de bepaling in het vorige lid slechts van toepassing op de eerste levering.</li>
                </ol>
            </section>
            <section>
                <h2>Artikel 6 - Herroepingsrecht</h2>
                <ol>
                    <li>De consument heeft het recht een dienstenovereenkomst en een overeenkomst voor de levering van digitale inhoud die niet op een materiële drager is geleverd, binnen een termijn van 14 dagen zonder opgave van redenen te ontbinden.</li>
                    <li>De in lid 1 genoemde bedenktijd vangt aan op de dag waarop de overeenkomst wordt gesloten.</li>
                    <li>Het herroepingsrecht vervalt zodra de ondernemer heeft bevestigd dat de aanvraag in behandeling is genomen. Bij herroeping na bevestiging van de overeenkomst is de consument het volledige aankoopbedrag aan de ondernemer verschuldigd voor de reeds door de ondernemer verrichtte werkzaamheden, waaronder, maar niet beperkt tot, de onder artikel 6.4 vermelde werkzaamheden.</li>
                    <li>De ondernemer start direct met de werkzaamheden na bevestiging van de overeenkomst. Deze werkzaamheden omvatten onder andere het inzien en beoordelen van de aanvraag.</li>
                    <li>Indien de consument gebruik wenst te maken van zijn herroepingsrecht, dient hij dit binnen de bedenktijd per e-mail te melden aan het op de website vermelde e-mailadres.</li>
                    <li>Het risico en de bewijslast voor de juiste en tijdige uitoefening van het herroepingsrecht ligt bij de consument.</li>
                    <li>Indien de consument herroept na eerst uitdrukkelijk te hebben verzocht dat de uitvoering van de dienst aanvangt tijdens de bedenktijd, is de consument de ondernemer een bedrag verschuldigd dat evenredig is aan het gedeelte van de verbintenis dat door de ondernemer is nagekomen op het moment van herroeping, vergeleken met de volledige nakoming van de verbintenis.</li>
                    <li>De consument draagt geen kosten voor de uitvoering van diensten indien de ondernemer de consument niet de wettelijk verplichte informatie over het herroepingsrecht heeft verstrekt.</li>
                    <li>Indien de consument gebruik maakt van zijn herroepingsrecht, worden alle aanvullende overeenkomsten van rechtswege ontbonden.</li>

                </ol>
            </section>

            <section>
                <h2>Artikel 7 - Overmacht</h2>
                <ol>
                    <li>De consument heeft het recht een dienstenovereenkomst en een overeenkomst voor de levering van digitale inhoud die niet op een materiële drager is geleverd, binnen een termijn van 14 dagen zonder opgave van redenen te ontbinden.</li>
                    <li>De in lid 1 genoemde bedenktijd vangt aan op de dag waarop de overeenkomst wordt gesloten.</li>
                    <li>Het herroepingsrecht vervalt zodra de ondernemer heeft bevestigd dat de aanvraag in behandeling is genomen. Bij herroeping na bevestiging van de overeenkomst is de consument het volledige aankoopbedrag aan de ondernemer verschuldigd voor de reeds door de ondernemer verrichtte werkzaamheden, waaronder, maar niet beperkt tot, de onder artikel 6.4 vermelde werkzaamheden.</li>
                    <li>De ondernemer start direct met de werkzaamheden na bevestiging van de overeenkomst. Deze werkzaamheden omvatten onder andere het inzien en beoordelen van de aanvraag.</li>
                    <li>Indien de consument gebruik wenst te maken van zijn herroepingsrecht, dient hij dit binnen de bedenktijd per e-mail te melden aan het op de website vermelde e-mailadres.</li>
                    <li>Het risico en de bewijslast voor de juiste en tijdige uitoefening van het herroepingsrecht ligt bij de consument.</li>
                    <li>Indien de consument herroept na eerst uitdrukkelijk te hebben verzocht dat de uitvoering van de dienst aanvangt tijdens de bedenktijd, is de consument de ondernemer een bedrag verschuldigd dat evenredig is aan het gedeelte van de verbintenis dat door de ondernemer is nagekomen op het moment van herroeping, vergeleken met de volledige nakoming van de verbintenis.</li>
                    <li>De consument draagt geen kosten voor de uitvoering van diensten indien de ondernemer de consument niet de wettelijk verplichte informatie over het herroepingsrecht heeft verstrekt.</li>
                    <li>Indien de consument gebruik maakt van zijn herroepingsrecht, worden alle aanvullende overeenkomsten van rechtswege ontbonden.</li>
                </ol>
            </section>

            <section>
                <h2>Artikel 8 - Betaling</h2>
                <ol>
                    <li>Voor zover niet anders is bepaald in de overeenkomst of aanvullende voorwaarden, dient de consument de verschuldigde bedragen aan de ondernemer te voldoen binnen 14 dagen na het sluiten van de overeenkomst. In geval van een overeenkomst tot het verlenen van een dienst, vangt deze termijn aan op de dag nadat de consument de bevestiging van de overeenkomst heeft ontvangen. </li>
                    <li>Indien de ondernemer het mogelijk maakt om de verschuldigde bedragen pas te voldoen nadat de ondernemer zijn initiële werkzaamheden heeft uitgevoerd, vangt het termijn aan op de dag dat de ondernemer zijn initiële werkzaamheden heeft uitgevoerd en de consument hierover heeft geïnformeerd. </li>
                    <li>De consument gaat, als toevoeging op alle andere mogelijke betaalmethoden,  akkoord met betaling door middel van een automatische incasso voor het afschrijven van de verschuldigde bedragen binnen het gestelde termijn van 14 dagen.</li>
                    <li>De consument heeft de plicht om onjuistheden in verstrekte of vermelde betaalgegevens onverwijld aan de ondernemer te melden.</li>
                    <li>Indien de consument niet tijdig aan zijn betalingsverplichting(en) voldoet, is deze, nadat hij door de ondernemer is gewezen op de te late betaling en de ondernemer de consument een termijn van 14 dagen heeft gegund om alsnog aan zijn betalingsverplichtingen te voldoen, na het uitblijven van betaling binnen deze 14-dagen-termijn, over het nog verschuldigde bedrag de wettelijke rente verschuldigd en is de ondernemer gerechtigd de door hem gemaakte buitengerechtelijke incassokosten in rekening te brengen. Deze incassokosten bedragen maximaal: 15% over openstaande bedragen tot € 2.500,=; 10% over de daaropvolgende € 2.500,= en 5% over de volgende € 5.000,= met een minimum van € 40,=. De ondernemer kan ten voordelen van de consument afwijken van genoemde bedragen en percentages.</li>
                </ol>
            </section>
            <section>
                <h2>Artikel 9 - Klachtenregeling</h2>
                <ol>
                    <li>Klachten over de uitvoering van de overeenkomst moeten binnen bekwame tijd nadat de consument de gebreken heeft geconstateerd, volledig en duidelijk omschreven worden ingediend bij de ondernemer. </li>
                    <li>Bij de ondernemer ingediende klachten worden binnen een termijn van 28 dagen gerekend vanaf de datum van ontvangst beantwoord. Als een klacht een voorzienbaar langere verwerkingstijd vraagt, wordt door de ondernemer binnen de termijn van 28 dagen geantwoord met een bericht van ontvangst en een indicatie wanneer de consument een meer uitvoerig antwoord kan verwachten.</li>
                    <li>De consument dient de ondernemer in ieder geval 8 weken de tijd te geven om de klacht in onderling overleg op te lossen. </li>
                    <li>Op overeenkomsten tussen de ondernemer en de consument waarop deze algemene voorwaarden betrekking hebben, is uitsluitend Nederlands recht van toepassing.</li>
                    <li>De rechter in de vestigingsplaats van de dienstverlener is bij uitsluiting bevoegd van geschillen kennis te nemen, tenzij de wet dwingend anders voorschrijft. Niettemin heeft de dienstverlener het recht het geschil voor te leggen aan de volgende wet bevoegde rechter. </li>
                    <li>Partijen zullen zich alvorens een beroep te doen op de Nederlandse rechter tot het uiterste inspannen om een geschil in der minne te beslechten. </li>
                </ol>
            </section>
        </div>
    </div>
</div>
