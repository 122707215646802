import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrls: [`./footer.component.css`, '../../pages/home/home.desktop.component.css']
})
export class FooterComponent {

  public environment = environment;

}
