<div class="childeren-section" >
    <form [formGroup]="formService.childForm">
        <div formArrayName="childForms">
            <div *ngFor="let childForm of formService.childForms.controls index as i" [ngClass]="'step '+String(i+1)" [formGroupName]="i">
                <div *ngIf="i>0" class="form-header" >
                    <div class="title-small"> Kind {{i+1}}</div>
                    <button class="delete-button" (click)="removeChild(i)">Verwijderen</button>
                </div>
                <div class="form-container">
                    <div class="section-header">
                        <div *ngIf="i==0">Kind gegevens</div>
                        <div *ngIf="i==0" style="font-weight: 400; font-size: 1rem">Voeg hier de gegevens van kinderen toe waarvoor u kinderbijslag wilt aanvragen</div>
                    </div>

                    <div class="field-group__container">
                        <div class="field-group__header">Naam en geboortedatum</div>
                        <div class="field-group">
                            <div class="field-group__row">
                                <app-text-field fieldName="Volledige voornamen"
                                                placeholder="Voornamen"
                                                formControlName="name"
                                                [submitted]="stepService.stepSubmitted[2]"/>
                                <app-text-field fieldName="Tussenvoegsels"
                                                formControlName="middleName"
                                                [submitted]="stepService.stepSubmitted[2]"/>
                                <app-text-field fieldName="Achternaam"
                                                formControlName="surname"
                                                [submitted]="stepService.stepSubmitted[2]"/>
                            </div>
                            <div class="field-group__row">
                                <app-date-field
                                    fieldName="Geboortedatum"
                                    formControlName="birthdate"
                                    [submitted]="stepService.stepSubmitted[2]"/>
                                <app-gender-field
                                    formControlName="gender"
                                    [submitted]="stepService.stepSubmitted[2]"/>
<!--
                                <div style="display: flex; flex: 1; flex-direction: column;"></div>
-->
                            </div>
                        </div>
                    </div>
                    <div class="field-group__container">
                        <div class="field-group__header">Andere ouder</div>
                        <div class="field-group">
                            <div class="radio-group">
                                <app-radio-field
                                    [fieldName]="question[0]"
                                    [options]="options[0]"
                                    formControlName="otherPartnerOfChild"
                                    (inputChange)="formService.setOtherKnownParent($event, i)"
                                    [submitted]="stepService.stepSubmitted[2]"/>
                                <div *ngIf="formService.showOtherPartnerOfChild(i)">
                                    <h4 >Andere ouder van het kind</h4>
                                    <div class="field-group_nospace" formGroupName="otherParent">
                                        <app-text-field fieldName="Voornaam"
                                                        formControlName="name"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-text-field fieldName="Initialen"
                                                        formControlName="initials"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-text-field fieldName="Tussenvoegsels"
                                                        formControlName="middleName"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-text-field fieldName="Achternaam"
                                                        formControlName="surname"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-date-field  fieldName="Geboortedatum"
                                                         formControlName="birthdate"
                                                         [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-text-field fieldName="Geboorteplaats"
                                                        formControlName="birthplace"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-select-field fieldName="Geboorteland"
                                                          formControlName="birthcountry"
                                                          [submitted]="stepService.stepSubmitted[2]"/>
                                        <app-text-field fieldName="Burgerservicenummer"
                                                        formControlName="bsn"
                                                        [submitted]="stepService.stepSubmitted[2]"/>
                                    </div>
                                </div >
                                <app-radio-field [fieldName]="question[1]"
                                                 [options]="options[1]"
                                                 formControlName="partnerWorksAbroad"
                                                 (inputChange)="formService.setPartnerWorksAbroad($event, i)"
                                                 [submitted]="stepService.stepSubmitted[2]"/>
                                <app-select-field *ngIf="formService.showPartnerWorkCountry(i)"
                                                  fieldName="Land"
                                                  formControlName="partnerWorkCountry"
                                                  [submitted]="stepService.stepSubmitted[2]"/>
                                <app-radio-field [fieldName]="question[2]"
                                                 [options]="options[2]"
                                                 formControlName="otherPartnerWorkedAbroad"
                                                 (inputChange)="formService.setOtherPartnerWorkedAbroad($event, i)"
                                                 [submitted]="stepService.stepSubmitted[2]"/>
                                <app-select-field *ngIf="formService.showOtherPartnerWorkCountry(i)"
                                                  fieldName="Land"
                                                  formControlName="otherPartnerWorkCountry"
                                                  [submitted]="stepService.stepSubmitted[2]"/>
                                <app-radio-field [fieldName]="question[3]"
                                                 [options]="options[3]"
                                                 formControlName="residence"
                                                 [submitted]="stepService.stepSubmitted[2]"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="add-section" (click)="addChild()">
        <img src="../../../../../assets/icons/add.svg" alt="add"/>
        <div>Voeg nog een kind toe</div>
    </div>
</div>
