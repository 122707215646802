import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Applicant, Application, Child, Partner, WorkAbroadPeriod, OtherCountry } from '../../models/applicant.model';
import { TextFieldComponent } from '../fields/text-field/text-field.component';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import {getShorthandId} from "../fields/Helpers";
import {CdkVirtualScrollableWindow} from "@angular/cdk/scrolling";

@Component({
  selector: 'app-application-dialogue',
  standalone: true,
  imports: [TextFieldComponent, CommonModule, MatExpansionModule],
  templateUrl: './application-dialogue.component.html',
  styleUrl: './application-dialogue.component.css'
})
export class ApplicationDialogueComponent {

    public isEditMode: boolean = false;
    public applicant: Applicant;
    public partner: Partner;
    public children: Child[];
    public workAbroadPeriods: WorkAbroadPeriod[] = [];
    public otherCountries: OtherCountry[][] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public application: Application) {
        this.applicant = application.applicant;
        this.partner = application.applicant.partner;
        this.children = application.applicant.children;
        this.workAbroadPeriods = application.applicant.workAbroadPeriods;
        console.log(this.workAbroadPeriods)
        for (let period of this.workAbroadPeriods) {
            let other = period.otherCountriesList;
            this.otherCountries.push(period.otherCountriesList);
        }
    }

    /*
      public getFullName(person: any){
        const name = person.name+" ";
        const middleName = person.middleName+" ";
        const surname = person.surname;
        return name+middleName+surname;
      }*/

    protected readonly getShorthandId = getShorthandId;
}
