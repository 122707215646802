<div class="info-page">
    <div class="info-section">
        <div class="info-container">
            <section>
                <h2 >Bedragen kinderbijslag</h2>
                <div>De kinderbijslag bedragen zijn per kind per kwartaal. De kinderbijslag wordt betaald na afloop van elk kwartaal.</div>
                <h3> Bedrag per kind per kwartaal</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Leeftijd kind</th>
                        <th>Bedrag per kwartaal in 2024</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>0 tot en met 5 jaar</td>
                        <td>€ 279,49</td>
                    </tr>
                    <tr>
                        <td>6 tot en met 11 jaar</td>
                        <td>€ 339,38</td>
                    </tr>
                    <tr>
                        <td>12 tot en met 17 jaar</td>
                        <td>€ 399,27</td>
                    </tr>
                    </tbody>
                </table>
                <div>De kinderbijslag wordt automatisch verhoogd als u kind een nieuwe leeftijdscategorie bereikt.</div>
            </section>

            <section>
                <h2>U heeft nog geen kinderbijslag</h2>
                <h3>Kinderbijslag voor het eerst geboren kind</h3>
                <div>Heeft u de geboorte van uw kind bij uw gemeente gemeld? Dan krijgt u binnen 4 weken vanzelf bericht dat u kinderbijslag kunt aanvragen</div>
                <h3>Kinderbijslag in een andere situatie</h3>
                <div>Naast de geboorte van een kind kunt u ook kinderbijslag aanvragen in een aantal andere situaties. </div>
                <ul>
                    <li>Een kind van uw partner komt bij u wonen</li>
                    <li>U heeft een pleegkind</li>
                    <li>U zorgt voor een kind alsof het uw eigen kind is</li>
                </ul>
            </section>

            <section>
                <h2> U heeft al kinderbijslag</h2>
                <h3>Wijziging doorgeven</h3>
                <div>Verandert er iets in uw situatie? En heeft dit gevolgen voor uw kinderbijslag? Geef dit binnen 4 weken aan ons door. Woont u buiten Nederland? Geef een wijziging dan binnen 6 weken aan ons door.</div>
                <h3>Een tweede of volgend kind</h3>
                <div>Nee, dit hoeft niet meer. Indien uw gezinssituatie verder niet veranderd, hoeft u niets aan te geven. Meld de geboorte van uw kind bij de gemeente en u krijgt vervolgens vanzelf de kinderbijslag uitbetaald. </div>
                <h3>Verhoging kinderbijslag</h3>
                <div>De kinderbijslag wordt automatisch verhoogd als u kind een nieuwe leeftijdscategorie bereikt. Hier hoeft u niks voor te doen.</div>
            </section>
        </div>
    </div>
</div>
