export function GetInputLabelClass(question: string):string {
    return question.includes("?")? "input-label__question":"input-label"
}

export function generateUniqueId(): string {
    return '_' + Math.random().toString(36).substring(2,9);
}

export function getShorthandId(Id: string){
    return "MB-"+Id.replace(/\D/g, '').substring(0, 5);
}

