import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MenuItem } from '../../SharedComponents/types';
import { CommonModule } from '@angular/common';
import { PlatformService } from '../../services/platform/platform.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu/menu.service';
import { RequestbuttonComponent } from '../../SharedComponents/requestbutton/requestbutton.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatSidenavModule, MatIconModule, CommonModule, RequestbuttonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})

export class HeaderComponent {

  currentPage: string = this.route.url;

  isMobile: boolean = false;
  private subscription: Subscription;

  constructor(private platformService: PlatformService, public route: Router, public menuService: MenuService){

    this.subscription = this.platformService
    .isMobile$
    .subscribe(isMobile=>{
      this.isMobile = isMobile;
    })

  }
  ngOndestroy(){
    this.subscription.unsubscribe();
  }

  isActive = (url: string):boolean =>{
    return "/"+url===this.route.url
  }

  pages: MenuItem[] = [
    {title: "Home", url: ""},
    {title: "Meer weten", url: "meer-weten"},
    {title: "Contact", url: "contact"},
    {title: "Meestgestelde vragen", url: "faq"},
  ]

  onLogoClick(){
    this.route.navigate(["/"])
  }
}
