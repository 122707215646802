import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private isMobileSubject = new Subject<boolean>();
  isMobile$ = this.isMobileSubject.asObservable();

  constructor(){
    this.CheckWindowSize();
    window.addEventListener('resize', ()=>{
      this.CheckWindowSize();
    })
    window.addEventListener('load', ()=>{
      this.CheckWindowSize();
    })
  }

  private CheckWindowSize(){
    const isMobile = window.innerWidth < 678;
    this.isMobileSubject.next(isMobile);
  }
}
