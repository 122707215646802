<div class="step 1">
    <div class="form-container">
        <div class="field-group__container">
            <div class="field-group__header">Reden voor aanvraag</div>
            <form [formGroup]="formService.applicationForm">
                <div class="field-group">
                    <div class="radio-group">
                        <app-radio-field
                            fieldName="Wat is de reden voor uw aanvraag?"
                            [options]="Options1_1"
                            [hasOpenField]="true"
                            formControlName="reason"
                            [submitted]="stepService.stepSubmitted[0]"
                            (inputChange)="onInputChanged($event)"/>
                        <div class="field-group__row" style="width: 50%">
                            <app-date-field
                                fieldName="Sinds welke datum speelt deze situatie"
                                formControlName="situationStartDate"
                                [submitted]="stepService.stepSubmitted[0]"/>
                        </div>
                        <app-radio-field
                            fieldName="Heeft u een partner"
                            [options]="Options1_3"
                            formControlName="hasPartner"
                            [submitted]="stepService.stepSubmitted[0]"
                            (inputChange)="this.formService.setApplicantPartner($event)"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
